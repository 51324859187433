// ResetForm.js
import React from 'react';
import { Link } from 'react-router-dom';

import { createPopup } from '../../../js/popup'

function getScreenSizeForTextSize() {
  if(window.innerWidth < 768) {
    return true
  }
  return false
}

const ResetForm = () => {

    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
    
      let data = {
        password1: formData.get("password1"),
        password2: formData.get("password2"),
        token: window.location.href.split("/reset/")[1]
      };

      // check if password are the same
      if(data.password1 !== data.password2) {
        alert("Les mots de passe ne sont pas identiques")
        return
      }

      delete data.password2
    
      const res = await fetch("https://kissaten.koffy.finance/user/reset_pwd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    
      const body = await res.json();
      if(body.success === "ok") {
        alert("Changement de mot de passe effectué avec succès")
        window.location.href = "/login"
      } else {
        createPopup(body.error_description)
      }
      
    };



  return (
        <div className="w-full h-100">
            <form onSubmit={handleSubmit}>
                <div>
                    <img className="h-12 mb-5" src='/imgs/koffy_logo.png' alt='koffy logo' />
                    <h1 className="text-xl md:text-2xl font-pt-serif font-bold leading-tight mt-12"><span
                            className="bg-underline1 bg-left-bottom bg-no-repeat pb-3 bg-100%">
                            Changement de mot de passe</span></h1>

                    <div className="mt-4">
                        <label className="font-montserrat block text-gray-700">Nouveau mot de passe</label>
                        <input id="password1" name="password1" type="password" placeholder="S3cur3P4ssw0rd"
                            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-black focus:bg-white focus:outline-none" required />
                    </div>
                    <div className="mt-4">
                        <label className="font-montserrat block text-gray-700">Confirmer le mot de passe</label>
                        <input id="password2" name="password2" type="password" placeholder="S3cur3P4ssw0rd"
                            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-black focus:bg-white focus:outline-none" required />
                    </div>
                    <div>
                        <button type="submit" className="w-full block bg-black hover:bg-white hover:bg-gray-200 hover:text-black text-white font-semibold rounded-lg
                    px-4 py-3 mt-6">Nouveau mot de passe</button>
                    </div>
                    
                    {
                      (!getScreenSizeForTextSize()) ? (
                        <div className="text-right mt-2">
                          <Link to="/login" className="text-sm font-semibold text-gray-700 hover:text-yellow-700 focus:text-yellow-700">

                          </Link>
                      </div>
                      ) : (<></>)
                    }

                    <hr className="my-6 border-gray-300 w-full" />

                    {
                      (!getScreenSizeForTextSize()) ? (
                        <p className="mt-8 font-montserrat">
                          <Link to="/register" className="text-black font-montserrat hover:text-black font-semibold">Vous n’avez pas
                          encore de compte ?</Link>
                      </p>
                      ) : (<></>)
                    }
                    <div></div>


                    {
                      // APP BE LIKE
                      (!getScreenSizeForTextSize()) ? (
                        <p className="mt-4 font-montserrat">
                          <a href='/' className="text-blue-600 hover:text-blue-700 font-semibold"> Revenir à l'accueil</a>
                        </p>
                      ) : (<></>)
                    }
                    
                </div>
            </form>
        </div>
  );
};

export default ResetForm;
