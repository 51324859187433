import React from 'react';
import { Flex, Box, Text, Strong, HoverCard, Link, Button  } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

const NoFeedBtn = (articleData) => {

    let lang = "FR"
    try {
        lang = articleData.userData.usrsett_lang
    } catch (error) {}

    return <>
            <Text>
                
                    {
                        lang === "FR" ?
                            (<>Oups ! Il semblerait que vous n'ayez pas encore de <Strong>KoFeed</Strong>. Pour en créer un, cliquez sur le bouton ci-dessous.</>):
                            (<>Oops ! It seems that you don't have any <Strong>KoFeed</Strong> yet. To create one, click on the button below.</>)
                    }
            </Text>

            <br />

            <Box className="flex justify-center" style={{marginTop: '20px'}}>
                <Link href="/settings">
                    <Button variant="outline" style={{marginRight: "1em"}}>
                        <Text>
                            {' '}
                            <HoverCard.Root>
                                <HoverCard.Trigger>
                                <Link href="/settings">
                                    {
                                        lang === "FR" ?
                                            "Créer mon KoFeed" :
                                            "Create my KoFeed"
                                    }
                                </Link>
                                </HoverCard.Trigger>
                                <HoverCard.Content>
                                <Flex gap="4">
                                    <Box>
                                        <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                           

                                            {
                                                lang === "FR" ?
                                                    (<> En cliquant sur ce bouton, vous serez redirigé vers la page de creation de votre <Strong>KoFeed</Strong>.</>) :
                                                    (<> By clicking on this button, you will be redirected to the creation page of your <Strong>KoFeed</Strong>.</>)
                                            }
                                        </Text>
                                    </Box>
                                </Flex>
                                </HoverCard.Content>
                            </HoverCard.Root>{' '}
                        </Text>
                    </Button>
                </Link>
            </Box>
    </>
}


export default NoFeedBtn;