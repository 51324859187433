import React from 'react';
import { Box, Text, HoverCard, Link, Flex } from '@radix-ui/themes';
import { InfoCircledIcon } from '@radix-ui/react-icons';

import ArticlesDouble from './articles/double.jsx';
import ArticlesSimple from './articles/simple.jsx';

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const Articles = (koffyData) => {

    const data = koffyData.koffyData.koffyData;

    if(!data) return <></>
    return <>

        <Box style={{height: '100%', display:"block", marginLeft: '20px', marginTop: "20px"}}>

            {
                (data.news && data.news.length > 0 && !checkSreenInf750() ) ? <Text size="6" weight="bold" style={{
                    display: "flex",
                }}>
                    
                    {
                        koffyData.koffyData.userData.usrsett_lang === "FR" ?
                            "Actualités :" :
                            "Latest news:"
                    }
                    

                        {' '}
                                <HoverCard.Root>
                                    <HoverCard.Trigger>
                                        <InfoCircledIcon width="20" height="20" style={{marginLeft: "10px"}} />
                                    </HoverCard.Trigger>
                                    <HoverCard.Content>
                                    <Flex gap="4">
                                        <Box>
                                            <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                                {
                                                    koffyData.koffyData.userData.usrsett_lang === "FR" ?
                                                    "Nos News sont issues de nos médias partenaires, résumées et reformulées par IA pour gagner en temps de lecture puis sélectionnées et vérifiées à la main par notre équipe." :
                                                    "Our News are from our partner media, summarized and rephrased by AI to save reading time and then selected and verified by our team."
                                                }
                                            </Text>
                                        </Box>
                                    </Flex>
                                    </HoverCard.Content>
                                </HoverCard.Root>{' '}
                </Text> : (<></>)
            }

            

        
            <Box style={{height: '100%', width: "100%", marginRight: '20px', marginTop: '20px', display: (checkSreenInf750()) ? "block" : "flex"}} className="md-10">
                
                <ArticlesSimple articleData={data.news[1]} />
                
                {
                    (checkSreenInf750()) ? <ArticlesSimple articleData={data.news[2]} /> : <ArticlesDouble articleData={data.news[2]} />
                }

            </Box>

            <Box style={{height: '100%', width: "100%", marginRight: '20px', marginTop: '20px', display: (checkSreenInf750()) ? "block" : "flex"}} className="md-10">

                <ArticlesSimple articleData={data.news[3]} />
                <ArticlesSimple articleData={data.news[4]} />
                <ArticlesSimple articleData={data.news[5]} />

            </Box>
            


        </Box>
    
    </>
}

export default Articles;