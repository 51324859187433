import React from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';



const NoContentWeekend = (userData) => {
    return <>
         {/* box full heigth full width with an image in the center */}

        <Box style={{ backgroundColor: 'white', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box style={{ display: "block"}}>
                <img src="https://media.tenor.com/KtjcgqI66w4AAAAC/nancy-drew-sorry-were-closed.gif" alt="loading" />
            
                <Text size='6' style={{ color: 'black' }} weight="bold">
                    {
                        userData.userData.usrsett_lang === "FR" ?
                            "Nous sommes fermés le week-end" :
                            "We are closed on weekends"
                    }
                </Text>
            </Box>
        </Box>
    </>

}


export default NoContentWeekend;