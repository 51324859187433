import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, HoverCard } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';


import NewsUI from './kofeed/NewsBlock.jsx';
import NoFeedBtn from './kofeed/NoFeedAvailable.jsx';

function prettyDate(date) {
    // "2023-10-03T04:00:30.000Z" to "2023-10-03"
    return date.replace("T", " ").replace(".000Z", "").split(" ")[0];
}

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const News = (userData) => {

    let [k, setK] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
        const token = cookie ? cookie.split('token=')[1] : null;
        // contact the api to get the user data
        fetch("https://kissaten.koffy.finance/kofeed/get", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
              "date": "2022-11-17"
          })
        })
        .then(res => res.json())
        .then(data => {
            setK(data.data);
            setLoading(false);
        })
    }, []);

    if(loading) return (
        <>
            <Box style={{height: '100%', display:"block", marginLeft: '20px'}}>
                <Text size="6" weight="bold">
                    My KoFeed ☕
                </Text>
                <Box style={{height: '100%', width: "100%", marginRight: '20px', marginTop: '20px', display: "flex"}} className="md-10">
                    <NewsUI time="2022-11-17" title="Chargement..." />
                </Box>
            </Box>
        </>
    )

    if(!k) return (
        <>
            <Box style={{height: '100%', display:"block", marginLeft: '20px'}}>
                <Text size="6" weight="bold">
                    My KoFeed ☕
                </Text>
                <Box style={{height: '100%', width: "100%", marginRight: '20px', marginTop: '20px', display: "flex"}} className="md-10">
                    <NoFeedBtn articleData={userData} />
                </Box>
            </Box>
        </>
    )

    // if size of screen is < 750px, only keep last 5 news
    if(checkSreenInf750()) {
        k = k.slice(0, 5);
    }
    
    return <>

        <Box style={
            checkSreenInf750() ?
            {height: '100%', display: "block", marginLeft: '20px', marginRight: '20px'}
            :
            {height: '100%'}
        }>
            {/*
                This news section is cut in 2 parts:
                - the title "My KoFeed"
                - the news list ( little timestamp, title, separator bar)
            */}
            <Text size="7" className='rt-Text' weight="bold">
                    {' '}
                    <HoverCard.Root>
                        <HoverCard.Trigger>
                            <Text>
                                My KoFeed ☕
                            </Text>
                        </HoverCard.Trigger>
                        <HoverCard.Content>
                        <Flex gap="4">
                                <Box>
                                    <Text as="div" size="2" style={{ maxWidth: 300 }}>
                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                                "My KoFeed est un service de flux en temps reel. Il vous permet de suivre les dernieres actualites du monde de la crypto.":
                                                "My KoFeed is a real-time feed service. It allows you to follow the latest news from the world of crypto."
                                        }
                                    </Text>
                                </Box>
                            </Flex>
                        </HoverCard.Content> 
                    </HoverCard.Root>
                    {' '}
                </Text>

            <Box className='rt-Box' style={{marginTop: "20px"}}>
                {
                    (k != null) ? k.map((news, index) => {
                        return <NewsUI key={index} time={prettyDate(news.newsraw_datetime)} title={news.newspro_trlstitle} link={news.newsraw_link} code={news.usht_code} />
                    }) : <></>
                }
                {
                    (k == null) ? <NoFeedBtn /> : <></>
                }
                {
                    (k != null) ? (k.length === 0) ? <NoFeedBtn /> : <></> : <></>
                }
            </Box>

        </Box>


    </>
}

export default News;