import React, { useEffect, useState } from 'react';
import { Box, Text, Card, Inset, Link, Button  } from '@radix-ui/themes';


function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

function html_to_text(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}

function checkurlargsfull() {
    // check /news?full=true
    const url = window.location.href;
    if(url.includes('full=true')) return true;
    else return false;
}

const Rssfeed = () => {

    // fetch all the rss feed
    const [rssData, setRssData] = useState(null)
    const [loading, setLoading] = useState(true);
  
    //show the current poath
    console.log(window.location.pathname);
  
    useEffect(() => {
      // contact the api to get the user data
      fetch("https://kissaten.koffy.finance/rss", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => res.json())
      .then(data => {
        setRssData(data);
        setLoading(false);
      })
      .catch(error => {
        // Handle fetch errors
        console.error('Error fetching user data:', error);
        setLoading(false);
      });
    }, []);
    
  
    if(loading) return (
        <Box css={{ padding: 20, backgroundColor: 'white', borderRadius: 6, boxShadow: '0 2px 10px rgba(0,0,0,.1)' }}>
            <p>Loading...</p>
        </Box>
    )

    let full = checkurlargsfull()

    return (
        <>
                {
                    (rssData != null) ? rssData.map((rss, index) => {
                        /*return <Box css={{ padding: 20, backgroundColor: 'white', borderRadius: 6, boxShadow: '0 2px 10px rgba(0,0,0,.1)' }}>
                            <h2>{rss.title}</h2>
                            <p>{rss.description}</p>
                            <a href={rss.link}>Read more</a>
                        </Box>*/

                        return <Box style={{height: '100%', width:  "100%", display: "block"}} id={rss.id}>
                        <Box style={{height: '100%', width: "100%", display: "block", marginBottom: "10px"}}>
                        
                            <Link href={
                                checkSreenInf750() ?
                                '#'+rss.id
                                :
                                rss.link
                            } style={{textDecoration: 'none', color: "black"}} target={
                                checkSreenInf750() ?
                                ''
                                :
                                "_blank"
                            }>
                                <Card size="2">
                                    <Text as="p" size="2" color='gray'>
                                        {
                                            rss.rssfeed_name + ' - ' + rss.pubDate
                                        }
                                    </Text>
                                    <Text as="p" size="3" weight="bold">
                                        {
                                            rss.title
                                        }
                                    </Text>
                                    <Text as="p" size="2">
                                        {
                                            full ? html_to_text(rss.description) : ""
                                        }{/*.slice(0, 250) + '...').replace('......', '...')*/}
                                    </Text>
            
                                </Card>
                            </Link>
                        </Box>
                    </Box>



                    }) : <></>
                }

                <Button  style={{marginTop: "10px"}} onClick={() => {
                                window.location.href = "/news?full=true";
                            }}>

                                Read with full description
                            </Button>
            
        </>
    );
}

export default Rssfeed;
