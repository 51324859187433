import React from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';



const noContent = () => {


    return <>
         {/* box full heigth full width with an image in the center */}

        <Box style={{ backgroundColor: 'white', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box style={{ display: "block"}}>
                <img src="https://media.tenor.com/MnLDOYdsJxgAAAAC/coffee-where-is-my-coffee.gif" alt="loading" />
            
                <Text size='6' style={{ color: 'black' }} weight="bold">
                    Contenu non trouvé pour cette date
                </Text>
            </Box>
        </Box>
    </>

}


export default noContent;