import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {

  return (
    <>
    <section
      className="pt-24 md:mt-0 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-48 md:px-12 px-4 bg-secondary">
      <div className="md:flex-1 md:mr-10">
        <h1 className="font-pt-serif text-7xl font-black mb-7">
          Erreur 404 😰
        </h1>
        <p className="font-pt-serif text-xl font-normal mb-7">
          La page que vous cherchez n’existe pas ou a été déplacée. <br/>
          Nous vous invitons à retourner à la page d’accueil, et à accéder à votre espace personnel.
        </p>
        <div className="font-montserrat">
          <Link to="/"className="px-6 py-4 border-2 border-black border-solid rounded-lg">
            Revenir à la page d'accueil
          </Link>
        </div>
      </div>
    </section>
    </>
  );
}

export default Error404;
