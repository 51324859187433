import React from 'react';

import { Box, Strong } from '@radix-ui/themes';

const Legal_footer = () => {
    return (
        <>

            <footer className="text-gray-600 body-font">
                <Box
                className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <Box className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" href='/'>
                        <img src="/imgs/koffy_logo.png" width="45px" alt='koffy logo'/>
                        <span className="ml-3 text-3xl font-extrabold">Koffy</span>
                        </a>
                        <p className="mt-2 text-sm text-gray-500">Ready to be read while drinking your Koffy</p>
                    </Box>
                    <Box className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <Box className="lg:w-1/2 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">LEGAL</h2>
                        <nav className="list-none mb-10">
                            <li>
                            <a className="text-gray-600 hover:text-gray-800" href="/legal">Legal</a>
                            </li>
                            <li>
                            <a className="text-gray-600 hover:text-gray-800" href="/cgu">Terms of service / CGU</a>
                            </li>
                            <li>
                            <a className="text-gray-600 hover:text-gray-800" href="/cgv">General subscription conditions / CGV</a>
                            </li>
                        </nav>
                        </Box>
                        <Box className="lg:w-1/2 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">Note:</h2>
                        <nav className="list-none mb-10">
                            <li>
                                "TWITTER, TWEET, RETWEET and the Twitter Bird logo are
                                trademarks of Twitter Inc. or its affiliates." <br /> Koffy is Powered by OpenSea and CoinGecko<br /> All
                                external contents featured belongs to their respective owners.
                            </li>
                        </nav>
                        </Box>
                    </Box>
                </Box>
                <Box className="bg-gray-100">
                    <Box className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p className="text-gray-500 text-sm text-center sm:text-left">© 2022 Koffy — Made with ❤️ and ☕

                        </p>
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                        <a className="ml-3 text-gray-500" href="https://twitter.com/koffy_finance">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5"
                            viewBox="0 0 24 24">
                            <path
                                d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">
                            </path>
                            </svg>
                        </a>
                        </span>
                    </Box>
                </Box>
            </footer>
        </>
    );
}

export default Legal_footer;