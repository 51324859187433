import React from 'react';
import { Flex, Text, Button, Box, Link, HoverCard, Strong } from '@radix-ui/themes';
import { ReaderIcon } from '@radix-ui/react-icons';


function display(base) {
    if(getScreenWidth() < 1000) {
        return "100%";
    }
    return base;
}

function getScreenWidth() {
    return window.innerWidth;
}


function marginTopSize() {
    if(getScreenWidth() < 1546) {
        return "10px";
    }
    return 0;
}


const rss = (userdata) => {
    return (
        <Link href="/news" color='gray' style={{ width: display('auto'), marginTop: marginTopSize() }}>
            <Button variant="outline" style={{marginRight: "1em",  width: display('auto')}}>
                <ReaderIcon width="25" height="18" /> <Text>
                    {' '}
                    <HoverCard.Root>
                        <HoverCard.Trigger>
                        <Link href="/news">
                            News
                        </Link>
                        </HoverCard.Trigger>
                        <HoverCard.Content>
                        <Flex gap="4">
                            <Box>
                                <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                    {
                                        userdata.userData.usrsett_lang === "FR" ?
                                        (<>Nouvelles en temps réel</>) :
                                        (<>Real-time news</>)
                                    }
                                </Text>
                            </Box>
                        </Flex>
                        </HoverCard.Content>
                    </HoverCard.Root>{' '}
                </Text>
            </Button>
        </Link>
    )
}

export default rss;