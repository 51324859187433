import React from 'react';
import { Box, Tabs } from '@radix-ui/themes';

import Footer from "../utils/footer.jsx"
import Header from "./compo/header.jsx";

import Settings_detail from './settings/settings.jsx'
import Settings_stripe from './settings/stripe.jsx'
import Settings_dandt from './settings/dandt.jsx'

function getScreenWidth() {
    return window.innerWidth;
}

const Settings = (userData) => {

    const u = userData.userData;

    return <Box style={{display: "block"}} >

            <Box>
                <Header userData={userData.userData}/>
            </Box>


            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">
                        {
                            userData.userData.usrsett_lang === "FR" ?
                                "Réglages" :
                                "Settings"
                        }
                    </span>
                </div>
            </div>

            {/* box full heigth full width with an image in the center */}
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20px", marginRight: "10%", marginLeft: "10%" }}>

                <Tabs.Root defaultValue="settings" style={{ display: 'block', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', height: '90vh' }}>
                    
                    {/* center this div */}
                    <Tabs.List style={{ margin: "auto", }}>
                        <Tabs.Trigger value="settings">
                            {
                                userData.userData.usrsett_lang === "FR" ?
                                    "Réglages Koffy" :
                                    "Koffy settings"
                            }
                        </Tabs.Trigger>

                        {
                            getScreenWidth() > 850 ?
                                <>
                                    <Tabs.Trigger value="settings2">
                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                                "Réglages Abonnements" :
                                                "Subscriptions settings"
                                        }
                                    </Tabs.Trigger>
                                </>
                                :
                                <></>
                        }

{
                            getScreenWidth() > 850 ?
                                <>
                                    <Tabs.Trigger value="settings3">
                                        Discord & Telegram
                                    </Tabs.Trigger>
                                </>
                                :
                                <></>
                        }
                        
                    </Tabs.List>

                    <Box>

                        <Tabs.Content value="settings">
                            <Settings_detail userData={u}/>
                        </Tabs.Content>

                        

                        {
                            getScreenWidth() > 850 ?
                                <>
                                    <br />
                                    <br />
                                    <Tabs.Content value="settings2">
                                        <Settings_stripe userData={u}/>
                                    </Tabs.Content>
                                </>
                                :
                                <></>
                        }

{
                            getScreenWidth() > 850 ?
                                <>
                                    <br />
                                    <br />
                                    <Tabs.Content value="settings3">
                                        <Settings_dandt userData={u}/>
                                    </Tabs.Content>
                                </>
                                :
                                <></>
                        }

                    </Box>
                </Tabs.Root>


            </Box>

            {
                getScreenWidth() > 850 ? <>
                    <br />
                    <br />
                    <Box style={{display: "block"}}>
                        <Footer userData={userData.userData}/>
                    </Box>
                </> : <></>
            }
            
    </Box>

}

export default Settings;