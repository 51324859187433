import React from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';


// Import the chart.js library
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js'
  import { Doughnut } from 'react-chartjs-2'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
)

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}


function getGraphData(twitter) {

    if(twitter.twitterData) {
        return [twitter.twitterData.twtov_neg, twitter.twitterData.twtov_neut, twitter.twitterData.twtov_pos]
    }
    return [0, 0, 0]
}


const Graph = (twitter) => {


    return  <Box style={{
        height: '100%',
        width: checkSreenInf750() ? '100%': "50%",
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Box style={{
            height: '60%',
            width: checkSreenInf750() ? '100%': "60%",
            alignElements: 'center'
        }}>
            <Doughnut data={{
                labels: ['Negative', 'Neutral', 'Positive'],
                datasets: [
                    {
                    label: '# of Votes',
                    data: getGraphData(twitter),
                    backgroundColor: [
                        'rgba(255, 79, 79, 0.2)',
                        'rgba(140, 140, 140, 0.2)',
                        'rgba(16, 98, 20, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 79, 79, 1)',
                        'rgba(140, 140, 140, 1)',
                        'rgba(16, 98, 20, 1)',
                    ],
                    borderWidth: 1,
                    dataoffset: 5,
                    },
                ],
            }} 
            />
            <br />
        </Box>
        {/** center all element is this box */}
        <Box style={{
            height: '60%',
            width: '60%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Text size="5">
                Twitter
            </Text>
        </Box>
    </Box>

}

export default Graph;