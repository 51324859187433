import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@radix-ui/themes';

// Import the chart.js library
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

function getGraphDate(date) {
    // convert date[0] (timestamp) to a date
    const d = new Date(date[0]);
    // get the day
    const day = d.getDate();
    // get the month
    const month = d.getMonth() + 1;
    // get the year
    const year = d.getFullYear();
    // return the date in the format DD/MM/YYYY
    return `${day}/${month}/${year}`;
}



const Graph = (userData) => {

    // get the data from the API
    const [d, setCoindata] = useState(null);

    useEffect(() => {
        // contact the api to get the user data
        fetch("https://kissaten.koffy.finance/price/full", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(data => {
            setCoindata(data);
        })
    }, []);

    if(d == null) return (<></>);

    return <>

        <Box style={{height: '100%', marginTop: '20px', marginRight: '40px'}}>

            {/* 2 elements by lines */}
            <Grid style={{height: '100%', display: 'flex', marginLeft: '20px'}} gap={2}>

                <Box style={{height: '100%', width: "49%" }} >
                    <Line
                        options={{
                            responsive: true,
                        }}
                        data={{
                            type: 'line',
                            datasets: [{
                                data: d.btc.graph,
                                label: "$BTC Price",
                                borderColor: "hsl(45, 100%, 50%)",
                            }],
                            labels: d.btc.graph.map((e) => getGraphDate(e)),
                        }}
                        style={{height: '30%', width: '30%'}}
                    />
                </Box>

                <Box style={{height: '100%', width: "49%"}} >
                    <Line
                        options={{
                            responsive: true,
                        }}
                        data={{
                            type: 'line',
                            datasets: [{
                                data: d.eth.graph,
                                label: "$ETH Price",
                                borderColor: "hsl(210, 14%, 50%)",
                            }],
                            labels: d.eth.graph.map((e) => getGraphDate(e)),
                            // show Y axis at the right
                            options: {
                                scales: {
                                    yAxes: [{
                                        position: 'right',
                                    }]
                                }
                            }
                        }}
                        style={{height: '30%', width: '30%'}}
                    />  
                </Box>

            </Grid>

        </Box>


    </>
}

export default Graph;
