import React from 'react';
import { Box, Grid, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

import Twitter1 from './SocialNetwork/Twitter.jsx';
import Twitter2 from './SocialNetwork/Twitter2.jsx';
import Graph from './SocialNetwork/Graph.jsx';

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const SocialNetwork = (koffyData) => {

    const data = koffyData.koffyData.koffyData;

    if(!data) return <></>

    return <>

        <Box style={{height: '100%', display:"block", marginLeft: '20px', marginTop: "40px",  marginRight: checkSreenInf750() ? '20px': "40px"}}>
            
            <Text size="6" weight="bold">
                {
                    koffyData.koffyData.userData.usrsett_lang === "FR" ?
                        "Analyse des réseaux sociaux" :
                        "Social network analysis"
                }
            </Text>

            {/* Img and graph. Cut in 2 same part */}

            <Grid style={{marginTop: '30px', display: checkSreenInf750() ? 'block': "flex"}}>

                <Box style={{
                    height: '100%',
                    width: checkSreenInf750() ? '100%': "50%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box style={{
                        height: '70%',
                        width: '70%',
                    }}>
                        <img src="https://alternative.me/crypto/fear-and-greed-index.png"  alt="crypto stats"/>
                    </Box>
                </Box>

                {
                    (data.twitter && data.twitter.length > 0) ? <Graph twitterData={data.twitter[0]} /> : (<></>)
                }

            </Grid>

            
            {
                (data.twitter && data.twitter.length > 0) ? <Text size="7" weight="bold" style={{marginTop: '70px', display: "block"}}>
                    {
                        koffyData.koffyData.userData.usrsett_lang === "FR" ?
                            "" :
                            ""
                    }
                </Text> : (<></>)
            }
            
            {
                (data.twitter && data.twitter.length > 0) ? <Twitter1 twitterData={data.twitter[0]} userData={koffyData.koffyData} /> : (<></>)
            }

            {
                (data.twitter && data.twitter.length > 0) ? <Twitter2 twitterData={data.twitter[0]} userData={koffyData.koffyData} /> : (<></>)
            }
            

        </Box>
    </>
}


export default SocialNetwork;