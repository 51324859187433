import React from 'react';
import { Text, Box } from '@radix-ui/themes';
import { FileTextIcon, TwitterLogoIcon, MagicWandIcon } from '@radix-ui/react-icons';


const Banner1 = () => {

    return (
            <>
                <Box className="bg-black text-white">
                    <Box style={{
                        width: '80%',
                        margin: "auto"
                    }}>

                        {/** Title of the section */}
                        <Box style={{
                            height: "110px",
                            alignItems: 'end',
                            display: 'flex',
                        }}>
                            <Box style={{
                                width: "100%",
                                textAlign: 'center',
                                marginBottom: "30px"
                            }}>
                                <Text size={"7"} weight={"light"} className='bg-underline2'>
                                    Un seul outil pour avoir une vue d’ensemble ! 📰
                                </Text>
                            </Box>
                        </Box>
        
                        <Box className="flex flex-col md:flex-row" style={{marginBottom: "30px"}}>
                            <Box className="flex-1 mx-8 flex flex-col items-center my-4">
                                <Box className="flex-1 mx-8 flex flex-col my-4">
                                    <Box style={{
                                        width: "100%",
                                        textAlign: 'center',
                                    }}>
                                        <Box style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: "10px",
                                            height: "65px",
                                        }}>
                                            <Box className="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
                                                <MagicWandIcon width="44px" height="32px" color="white" style={{marginTop: "5px"}} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <h3 className="font-montserrat font-bold text-xl mb-2">Intelligence Artificielle</h3>
                                <p className="font-montserrat">
                                    Nous utilisons des systèmes experts pour résumer les articles de presse, traiter de grandes quantités de données financières et vous fournir les meilleures informations. 
                                </p>
                            </Box>
                            <Box className="flex-1 mx-8 flex flex-col items-center my-4">
                                <Box style={{

                                    textAlign: 'center',
                                }}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: "10px",
                                        height: "65px",
                                    }}>
                                        <Box className="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
                                            <FileTextIcon width="44px" height="32px" color="white" style={{marginTop: "5px"}} />
                                        </Box>
                                    </Box>
                                </Box>

                                <h3 className="font-montserrat font-bold text-xl mb-2">Actualités</h3>
                                <p className="font-montserrat">
                                    Nous sommes en mesure de vous offrir un concentré d’actualités grâce à nos partenaires et à notre algorithme.
                                </p>
                            </Box>
                            <Box className="flex-1 mx-8 flex flex-col items-center my-4">
                                <Box style={{
                                    width: "100%",
                                    textAlign: 'center',
                                }}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: "10px",
                                        height: "65px",
                                    }}>
                                        <Box className="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3">
                                            <TwitterLogoIcon width="44px" height="32px" color="white" style={{marginTop: "5px"}} />
                                        </Box>
                                    </Box>
                                </Box>
                                <h3 className="font-montserrat font-bold text-xl mb-2">Réseaux sociaux</h3>
                                <p className="font-montserrat">
                                    Nous analysons les posts crypto des réseaux sociaux pour vous fournir le ressenti principal des utilisateurs. 
                                </p>
                            </Box>
                        </Box>
        
                        {/** A centered div for for 2 pic */}
                        <Box style={{
                            width: "100%",
                            textAlign: 'center',
                            marginBottom: "30px",
                        }}>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: "10px",
                                height: "65px",
                            }}>
                                <a href="http://embedded.koffy.finance/koffy-v6.apk" target='_blank' rel="noreferrer" style={{
                                    height: "100%",
                                    marginBottom: "30px"
                                }}>
                                    <img src="/imgs/app/apk-download-badge.png" style={{
                                        height: "100%"
                                    }} alt='android logo'/>
                                </a>
                                <a href="https://apps.apple.com/fr/app/koffy/id6474247267" target='_blank' rel="noreferrer" style={{
                                    height: "100%",
                                    marginBottom: "30px"
                                }}>
                                    <img src="/imgs/app/Appstore.png" style={{
                                        height: "100%",
                                    }}  alt='ip logo'/>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
    );
    

}


export default Banner1