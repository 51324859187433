import React from 'react';
import { Box, Text, Card, Inset, Link, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

function prettyDate(date) {
    // transform: "2023-10-20T00:00:00.000Z" to "20/10/2023"
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    return `${day}/${month}/${year}`;
}

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const ArticlesSimple = (articleData) => {

    const d = articleData.articleData;

    function redirectToWebPage(url) {
        navigator.clipboard.writeText(url).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            alert("Link copied to clipboard")
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    function getRandomString() {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for ( var i = 0; i < 10; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    const token = getRandomString();

    if(!d) return <></>

    return <Box style={{height: '100%', width: '100%', minWidth: "350px", display: "block"}} id={token}>
        <Box style={{height: '100%', width: "90%", display: "block"}}>
        
            <Link href={
                    checkSreenInf750() ?
                        "#"+token :
                        d.newsraw_link
                    } target={
                        checkSreenInf750() ?
                            "" :
                            '_blank'
                    } style={{textDecoration: 'none', color: "black"}}>
                <Card size="2">
                    <Inset clip="padding-box" side="top" pb="current">
                        <img
                        src={d.newsraw_illu}
                        alt="Bold typography"
                        style={{
                            display: 'block',
                            objectFit: 'cover',
                            width: '100%',
                            height: 140,
                            backgroundColor: 'var(--gray-5)',
                        }}
                        />
                    </Inset>
                    <Text as="p" size="2" color='gray'>
                        {prettyDate(d.mored_daystamp)}
                    </Text>
                    <Text as="p" size="3" weight="bold">
                        {d.newspro_trlstitle}
                    </Text>
                    <Text as="p" size="2">
                        {(d.newspro_body)}{/*.slice(0, 250) + '...').replace('......', '...')*/}
                    </Text>
                    {
                        checkSreenInf750() ? 
                        <Button onClick={() => redirectToWebPage(d.newsraw_link)} style={{marginTop: "10px", backgroundColor: "#000000"}}>
                            {
                                d.newspro_lang === "FR" ?
                                    "Copier le lien" :
                                    "Copy link"
                            }
                        </Button>
                        :
                        <></>
                    }
                </Card>
            </Link>

        </Box>
    </Box>

}

export default ArticlesSimple;