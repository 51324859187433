import React from 'react';
import { Box, Text, Flex, RadioGroup, Card, Strong, Separator, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

const Settings_stripe = (userData) => {


    return <>
     <Box style={{marginTop: "0px", display: "flex", justifyContent: 'flex-start', flexWrap: "wrap", boxSizing: "border-box", padding: '10px'}}>
            <Flex direction="column" gap="3" style={{margin: '1px', width: "30%"}}>

                    <Card size="2" style={{ width: "100%", height: "100%" }}>
                        <Text as="p" size="6">
                            <Strong>
                                {
                                    userData.userData.usrsett_lang === "FR" ?
                                        "Code d'activation" :
                                        "Activation code"
                                }
                            </Strong>
                        </Text>
                        <Separator my="3" size="4" />
                        <Text as="p" size="4">
                            {
                                userData.userData.devtoken
                            }
                        </Text>
                    </Card>

            </Flex>
        </Box>
    
    </>

}

export default Settings_stripe;