import React from 'react';
import { Text, Box, Grid, Card, Strong } from '@radix-ui/themes';


function getScreenSizeForColCount() {
    if(window.innerWidth < 768) {
        return "1"
    } else {
        return "4"
    }
}

const Tarrif = () => {

    return <>
    
        <Box className="bg-secondary py-0 pb-20" id="pricing">
             <Box style={{
                textAlign: "center"
            }}>
                <Text size={"7"} weight={"bold"}>Tarifs</Text>
            </Box>

            <Box>
                <Box style={{
                    width: "100%",
                    alignItems: "center",
                    textAlign: "center"
                }}>
                    <Box>
                            {/** Info box */}
                            <Box style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    marginBottom: "30px",
                                    justifyContent: "center", // Center horizontally
                                    alignItems: "center",     // Center vertically
                                    marginLeft: "30px",
                                    marginRight: "30px",
                                }}>
                                <Box style={{
                                    backgroundColor: "rgba(31, 41, 55)",
                                    color: "white",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px"
                                }}>
                                    <Text weight={"bold"} style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    display: "flex",
                                    marginTop: "4px",
                                    marginBottom: "4px"
                                    }}>
                                        Vous pouvez choisir de payer par carte / ApplePay en utilisant Stripe ou en crypto avec Utrust
                                    </Text>
                                </Box>

                            </Box>
                    </Box>
                </Box>
            </Box>

            <Grid columns={getScreenSizeForColCount()} gap="4" width="auto">

                <Card style={{ width: "95%", marginRight: "10px", marginLeft: "10px" }}>
                    <Text as="p" size="5" weight={"bold"}>
                        Mensuel
                    </Text>
                    <Text as="p" size="4" weight={"bold"}>
                        <Strong>2€</Strong>/mois
                    </Text>
                    <Text as="p" size="3">
                        <br />Toutes les éditions quotidiennes de Koffy, accès à la machine à remonter le temps et personnalisation de l’édition ! 
                        Pour le prix d’une tasse de café, par mois !
                    </Text>
                </Card>

                <Card style={{ width: "95%", marginRight: "10px", marginLeft: "10px" }}>
                    <Text as="p" size="5" weight={"bold"}>
                        Annuel
                    </Text>
                    <Text as="p" size="4" weight={"bold"}>
                        <Strong>20€</Strong>/an
                    </Text>
                    <Text as="p" size="3">
                    <br />Pour les accros au Koffy ! Mêmes fonctionnalités que dans l’édition mensuelle, pour moins cher (par mois) !
                    </Text>
                </Card>

                <Card style={{ width: "95%", marginRight: "10px", marginLeft: "10px" }}>
                    <Text as="p" size="5" weight={"bold"}>
                        A vie
                    </Text>
                    <Text as="p" size="4" weight={"bold"}>
                        <Strong>200€</Strong> une fois
                    </Text>
                    <Text as="p" size="3">
                        <br />La meilleure façon de nous soutenir! Abonnez-vous une fois pour toutes!
                    </Text>
                </Card>

                <Card style={{ width: "95%", marginRight: "10px", marginLeft: "10px" }}>
                    <Text as="p" size="5" weight={"bold"}>
                        Télégram
                    </Text>
                    <Text as="p" size="4" weight={"bold"}>
                        <Strong>49 €</Strong>/mois
                    </Text>
                    <Text as="p" size="3">
                        <br />Une édition quotidienne de Koffy sur Telegram, avec les mêmes fonctionnalités que dans l’édition mensuelle !
                    </Text>
                </Card>

            </Grid>


        </Box>

    </>
}

export default Tarrif