import React from 'react';
import { Box } from '@radix-ui/themes';
import Rssfeed from './rss/index.jsx';

import Header from './compo/header.jsx';
import Footer from '../utils/footer.jsx'


/**
 * This page is the time machine page
 * This is used to diplay the main informations
 * but from a specific date
 */
const Rss = (userData) => {
    return <>
    <Header userData={userData.userData}/>

    <Box style={{padding: '50px'}}>
        <Rssfeed/>
    </Box>
    
    <Footer/>
    </>
}

export default Rss;
