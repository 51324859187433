import React from 'react';
import { Box } from '@radix-ui/themes';

import Header from './compo/header.jsx';
import Footer from '../utils/footer.jsx'

import LibraryPage from './library/main.jsx'


function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}


/**
 * This page is the home page for the logged user
 * This is used to diplay the main informations
 */
const Library = ({ userData, time }) => {

    return (
        <>
            <Box>
                <Header userData={userData}/>
            </Box>

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500"> Koffy - Since 2022 </span>
                </div>
            </div>

            <Box style={{marginTop: "20px"}}>
                <LibraryPage userData={userData}/>
            </Box>

            {
                checkSreenInf750() ?
                   <></>
                :
                <Box>
                    <Footer userData={userData}/>
                </Box>
            }

        </>
    );
}

export default Library;
