import React from 'react';
import HomePageLogged from './home.jsx';
import Error404 from '../errors/404.jsx';
import ToFuture from './TimeMachine/ToFuture.jsx';
import ToOld from './TimeMachine/ToOld.jsx';


function checkDateIsValid(date) {

    // date regex test: YYYY-MM-DD
    const regex = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$");
    if(!regex.test(date)) return false;

    return true
}

function checkDateIsToOld(date) {
    // minimum date is 2021-01-01
    const minimumDate = new Date("2021-01-01");
    const dateToTest = new Date(date);
    if(dateToTest < minimumDate) return true;
    return false;
}

function checkDateIsNotInFuture(date) {
    const dateToTest = new Date(date);
    const today = new Date();
    if(dateToTest > today) return false;
    return true;
}

/**
 * This page is the time machine page
 * This is used to diplay the main informations
 * but from a specific date
 */
const TimeMachine = (userData) => {

    // get the curent url
    const date = window.location.href.split("/")[4];
    console.log(date);
    console.log(`To old: ${checkDateIsToOld(date)}`);

    if(checkDateIsValid(date)) {
        if(checkDateIsToOld(date)) {
            return <ToOld userData={userData.userData}/>
        } else if(!checkDateIsNotInFuture(date)) {
            return <ToFuture userData={userData.userData}/>
        } else {
            return <HomePageLogged userData={userData.userData} time={date}/>
        } 
    } 

    return <Error404/>
}

export default TimeMachine;
