import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, HoverCard } from '@radix-ui/themes';
import { ArrowTopRightIcon, ArrowBottomRightIcon } from '@radix-ui/react-icons';

function onlyKeepTwoDecimals(value) {
    return Math.round(value * 100) / 100;
}

function getPercentageChange(oldNumber, newNumber) {
    return ((newNumber - oldNumber) / oldNumber) * 100;
}

const Wallet = (userData) => {

    // get the data from the API
    const [data, setCoindata] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        // contact the api to get the user data
        fetch("https://kissaten.koffy.finance/price/full", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => res.json())
        .then(data => {
            setCoindata(data);
            setLoading(false);
        })
    }, []);

    if(loading || !data) return (
        <>
            <Box style={{height: '100%', width: "95%", marginTop: "20px", display: "block"}}>
                <Text size="6" weight="bold" style={{width: "80%"}}>
                    Loading...
                </Text>
            </Box>
        </>
    )

     

    let p_btc = getPercentageChange(data.btc.graph[0][1], data.btc.graph[data.btc.graph.length-1][1])
    let p_eth = getPercentageChange(data.eth.graph[0][1], data.eth.graph[data.eth.graph.length-1][1])
    //let p_xmr = getPercentageChange(data.xmr.graph[0][1], data.xmr.graph[data.xmr.graph.length-1][1])

    return <>

        <Box css={{height: '100%'}}>
            {/*
                This news section is cut in 2 parts:
                - the title "My KoFeed"
                - the news list ( little timestamp, title, separator bar)
            */}
            <Text size="6" className='rt-Text' weight="bold">
                    {' '}
                    <HoverCard.Root>
                        <HoverCard.Trigger>
                            <Text>
                                
                                {
                                    userData.userData.koffyData.userData.usrsett_lang === "FR" ?
                                        "Marché 🎐" :
                                        "Market 🎐"
                                }
                            </Text>
                        </HoverCard.Trigger>
                        <HoverCard.Content>
                        <Flex gap="4">
                                <Box>
                                    <Text as="div" size="2" style={{ maxWidth: 300 }}>
                                        {
                                            userData.userData.koffyData.userData.usrsett_lang === "FR" ?
                                                "Voila la valeur des plus grosses crypto monnaies ! C'est aussi simple que ça chez Koffy" :
                                                "Here is the value of the biggest crypto currencies! It's as simple as that at Koffy"
                                        }
                                    </Text>
                                </Box>
                            </Flex>
                        </HoverCard.Content> 
                    </HoverCard.Root>
                    {' '}
                </Text>

            <Box style={{height: '100%', width: "95%", display: "block"}}>
                {/*
                    Display current crypto value
                */}

                <Box style={{height: '100%', width: "95%", marginTop: "20px", display: "block"}}>

                    <Box style={{ display: 'flex'}}>
                        <Text size="2" className='rt-Text' style={{
                            color: '#a4a4a4',
                        }} weight='bold'>Bitcoin</Text>

                                {
                                    p_btc < 0 ?
                                        <ArrowBottomRightIcon style={{color: "#EC5D5E", marginLeft: "10px"}}/> :
                                        <ArrowTopRightIcon style={{color: "#71D083", marginLeft: "10px"}}/>
                                }

                                {
                                    p_btc < 0 ?
                                        <Text size="1" className='rt-Text' style={{
                                            color: '#EC5D5E',
                                        }} weight='bold'> {onlyKeepTwoDecimals(p_btc)}%</Text> :
                                        <Text size="1" className='rt-Text' style={{
                                            color: '#71D083',
                                        }} weight='bold'> +{onlyKeepTwoDecimals(p_btc)}%</Text>
                                }

                    </Box>

                    <Text size="6" weight="bold" style={{width: "80%"}}>
                    {
                        data === null ? "loading" : onlyKeepTwoDecimals(Number(
                            userData.userData.koffyData.userData.usrsett_curr_index === "usd" ? data.btc.value_usd : data.btc.value
                        )) + (userData.userData.koffyData.userData.usrsett_curr_index === "usd" ? " $" : " €")
                    }
                    </Text><br /><br />

                    <Box style={{ display: 'flex'}}>
                        <Text size="2" className='rt-Text' style={{
                            color: '#a4a4a4',
                        }} weight='bold'>Ethereum</Text>

                                {
                                    p_eth < 0 ?
                                        <ArrowBottomRightIcon style={{color: "#EC5D5E", marginLeft: "10px"}}/> :
                                        <ArrowTopRightIcon style={{color: "#71D083", marginLeft: "10px"}}/>
                                }

                                {
                                    p_eth < 0 ?
                                        <Text size="1" className='rt-Text' style={{
                                            color: '#EC5D5E',
                                        }} weight='bold'> {onlyKeepTwoDecimals(p_eth)}%</Text> :
                                        <Text size="1" className='rt-Text' style={{
                                            color: '#71D083',
                                        }} weight='bold'> +{onlyKeepTwoDecimals(p_eth)}%</Text>
                                }
                    </Box>

                    <Text size="6" weight="bold" style={{width: "80%"}}>
                    {
                        data===null ? "loading" : onlyKeepTwoDecimals(Number(
                            userData.userData.koffyData.userData.usrsett_curr_index === "usd" ? data.eth.value_usd : data.eth.value
                        )) + (userData.userData.koffyData.userData.usrsett_curr_index === "usd" ? " $" : " €")
                    }    
                    </Text><br /><br />

                    {/* Monero 
                    <Box style={{ display: 'flex'}}>
                        <Text size="2" className='rt-Text' style={{
                            color: '#a4a4a4',
                        }} weight='bold'>Monero</Text>

                                {
                                    p_xmr < 0 ?
                                        <ArrowBottomRightIcon style={{color: "#EC5D5E", marginLeft: "10px"}}/> :
                                        <ArrowTopRightIcon style={{color: "#71D083", marginLeft: "10px"}}/>
                                }

                                {
                                    p_xmr < 0 ?
                                        <Text size="1" className='rt-Text' style={{
                                            color: '#EC5D5E',
                                        }} weight='bold'> {onlyKeepTwoDecimals(p_xmr)}%</Text> :
                                        <Text size="1" className='rt-Text' style={{
                                            color: '#71D083',
                                        }} weight='bold'> +{onlyKeepTwoDecimals(p_xmr)}%</Text>
                                }
                    </Box>

                    <Text size="6" weight="bold" style={{width: "80%"}}>
                    {
                        data===null ? "loading" : onlyKeepTwoDecimals(Number(
                            userData.userData.koffyData.userData.usrsett_curr_index === "usd" ? data.xmr.value_usd : data.xmr.value
                        )) + (userData.userData.koffyData.userData.usrsett_curr_index === "usd" ? " $" : " €")
                    }    
                    </Text><br /><br />*/}
                </Box>
            </Box>
        </Box>

    </>
}

export default Wallet;