import React from 'react';
import { Box } from '@radix-ui/themes';
import { } from '@radix-ui/react-icons';


import MainHeader from './MainHeader.jsx';
import Articles from './Articles.jsx';
import Graph from './Graph.jsx';
import Jobs from './Jobs.jsx';
import Nft from './nft.jsx';
import SocialNetwork from './SocialNetwork.jsx';

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}


const MainBody = (koffyData) => {
    

    return <>

        <Box style={{height: '100%'}}>
            
            {/*
                This section is recut in 2 parts:
                Header: -> MainHeader.jsx
                Body: -> here
             */}
            <MainHeader koffyData={koffyData}/>
            {
                checkSreenInf750() ? <></> : <Graph koffyData={koffyData}/>
            }
            <Articles koffyData={koffyData}/>
            {
                checkSreenInf750() ? <></> : <Jobs koffyData={koffyData}/>
            }
            <Nft koffyData={koffyData}/>
            <SocialNetwork koffyData={koffyData}/>
            
        </Box>
    
    </>
}

export default MainBody;