import React from 'react';
import { Text, Box, Grid } from '@radix-ui/themes';
import { ChatBubbleIcon } from '@radix-ui/react-icons';

function getScreenSizeForColCount() {
    if(window.innerWidth < 768) {
        return "1"
    } else {
        return "3"
    }
}


function getScreenSizeForColDisplay() {
    if(window.innerWidth < 768) {
        return "block"
    } else {
        return "flex"
    }
}

const Business = () => {

    const redirectToCall = () => {
        document.location.href = 'https://cal.com/koffy'
    }

    return <>


        <Box className="bg-secondary" id="b2b">
            <Box style={{
                textAlign: "center"
            }}>
                <Text size={"7"} weight={"bold"}>Découvrez nos solutions B2B !</Text>
            </Box>

            <Grid columns={getScreenSizeForColCount()} gap="3" width="auto" style={{display: getScreenSizeForColDisplay()}}>

                <Box style={{ width: "90%", marginRight: "10px", marginLeft: "10px", border: "solid 0px" }}>
                    <Box className="md:grid">
                        <Box className="items-start font-montserrat">
                            <Box className="h-5 mr-4">
                                <i className="fa-solid fa-building-columns"></i>
                            </Box>
                            <Text className="font-semibold font-pt-serif text-2xl mb-5">Koffy peut fournir des solutions personnalisées à votre entreprise !</Text>
                            <br />
                            <Text>
                                Ce que nous pouvons configurer pour vous :<br />
                                <br />
                                - Médias internes et surveillance du marché<br />
                                - Intégrez des actualités et des données à votre site web via notre API<br />
                                - Intégrez toute l’édition Koffy dans votre espace client<br />
                                
                                <Box style={{
                                    justifyContent: "center",
                                    display: "flex",
                                }}>
                                    <button onClick={redirectToCall} className="mt-5 spx-6 pl-5 pr-5 py-4 border-2 border-black border-solid rounded-lg hover:bg-black hover:text-white flex">
                                        Prendre un rendez-vous <ChatBubbleIcon style={{ marginLeft: "10px", height: "25px"}}/>
                                    </button>
                                </Box>
                            </Text>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ width: "90%", marginRight: "10px", marginLeft: "10px", justifyContent: "center", display: "flex" }}>
                    <Box className="flex items-center font-montserrat my-6 mr-10">
                        <Box>
                            <a target="_blank" rel="noreferrer" href="https://coinstancy.com"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/coinstancy.png"/></a>
                            <a target="_blank" rel="noreferrer" href="https://ambrosia-crypto.fr/"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/logo_ambro.webp"/></a>
                            <a target="_blank" rel="noreferrer" href="https://alyra.fr/"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="https://global-uploads.webflow.com/638473041a643140c382a182/638478d7354bb2632a391edc_Logo%20Alyra.svg"/></a>
                            <a target="_blank" rel="noreferrer" href="https://montaignepatrimoine.fr/"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/logo-montaigne-patrimoine.png"/></a>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ width: "90%", marginRight: "10px", marginLeft: "10px", justifyContent: "center", display: "flex" }}>
                    <Box className="flex items-center font-montserrat my-6 mr-10">
                        <Box>
                            <a target="_blank" rel="noreferrer" href="https://lawforcode.com/"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/logo_lfc.png"/></a>
                            <a target="_blank" rel="noreferrer" href="https://kryptosphere.org/"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/logo_ks.png"/></a>
                            <a target="_blank" rel="noreferrer" href="https://www.crypto-expert.ch"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/crypto-expert.png"/></a>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/neomafinancialclub/?originalSubdomain=fr"><img width="200px" className="ml-20 pb-10 rounded-md object-cover object-center rounded" alt="hero" src="/imgs/partners/logo_nfc.png"/></a>
                        </Box>
                    </Box>
                </Box>

            </Grid>


        </Box>
    </>

}

export default Business