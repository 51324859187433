import React from 'react';
import { Box, Grid, Text, Card, Link  } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

const ArticlesDouble = (articleData) => {

    const d = articleData.articleData;

    if(!d) return <></>

    return  <Box style={{height: '100%', width: "61%", display: "block", border: 'none'}}>
        <Box style={{height: '100%', width: "100%", display: "block", border: 'none'}}>
            
            {/* NO border in the card */}
            <Card size="2" style={{border: '0px'}}>
                <Link href={d.usht_sht} style={{textDecoration: 'none', color: "black"}} target='_blank'>

                    <Grid style={{height: '100%', display: 'flex', marginLeft: '20px'}}>
                        {/* 2 elemtents: left: picture, rigth: text */}

                            <Box style={{height: '100%', width: "50%"}} >
                                
                                {/*
                                    this section is the biggest part of the page
                                    main a big image
                                    under this: title, description, button
                                */}
                                <Box style={{height: '100%', width: "95%", marginTop: "auto", display: "block"}}>
                                    <img src={d.newsraw_illu} alt='test' height='90%' style={{borderRadius: "3px"}} />
                                </Box>
                            </Box>
                            <Box style={{height: '100%', width: "50%", marginLeft: '20px'}} className="md-10">
                                <Text as="p" size="2" color='gray'>
                                    {d.newsraw_source}
                                </Text>
                                <Text size="6" weight="bold" style={{width: "80%"}}>{d.newspro_trlstitle}</Text><br />
                                <Text style={{width: "80%"}}>
                                    {d.newspro_body}
                                </Text>
                            </Box>
                    
                    </Grid>
                </Link>

            </Card>

        </Box>
    </Box>
}

export default ArticlesDouble;