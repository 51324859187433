import React from 'react';
import { Box, Text, Link, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const Nft = (koffyData) => {

    const data = koffyData.koffyData.koffyData;

    function redirectToWebPage(url) {
        navigator.clipboard.writeText(url).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            alert("Link copied to clipboard")
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    if(!data) return <></>

    return <>
       <Box style={{height: '100%', display:"block", marginLeft: '20px', marginTop: "40px",  marginRight: checkSreenInf750() ? '20px' : '40px'}}>

            {
                (data.nft.length > 1) ? ( <Text size="6" weight="bold">
                    NFT Art
                </Text>) : <></>
            }

            <div className="flex flex-wrap -mx-4 -mb-10 text-center" style={{marginTop: '30px'}}>

                {
                    (data.nft.length > 1) ? (
                        data.nft.map((nft, index) => (
                            
                            <div className="sm:w-1/2 mb-10 px-4">
                                <div className="relative rounded-lg h-80 l-80 overflow-hidden">
                                    <Link href={
                                        checkSreenInf750() ?
                                            "#" :
                                            nft.nft_itemlink
                                    } target={
                                        checkSreenInf750() ?
                                            "" :
                                            '_blank'
                                        }>
                                        <img className="object-cover object-center h-full w-full" src={nft.nft_itemimgurl} alt="nft"/>
                                    </Link>
                                    <span className="absolute border-white border-4 h-16 w-16 bottom-5 left-5 rounded-full">
                                        <Link href={
                                            checkSreenInf750() ?
                                                "#" :
                                                nft.nft_colurl
                                        } target={
                                            checkSreenInf750() ?
                                                "" :
                                                '_blank'
                                            }>
                                            <img className="rounded-full h-full w-full" src={nft.nft_colimgurl} alt="nft" />
                                        </Link>
                                    </span>
                                </div>
                                <span className="title-font text-2xl font-black text-gray-900 mt-6 mb-3">{nft.nft_colname}</span>
                                <p className="leading-relaxed text-base">
                                    {
                                        koffyData.koffyData.userData.usrsett_lang === "FR" ?
                                            (<>Prix plancher de la collection : {nft.nft_floorprice} ETH <br /> Volume total de la collection : {nft.nft_totalvolume} ETH</>):
                                            (<>Collection floor price : {nft.nft_floorprice} ETH <br /> Collection total volume : {nft.nft_totalvolume} ETH</>)
                                    }
                                </p>
                                    {
                                       checkSreenInf750() ? 
                                        <Button onClick={() => redirectToWebPage(nft.nft_colurl)} style={{marginTop: "10px", backgroundColor: "#000000"}}>
                                            {
                                                koffyData.koffyData.userData.usrsett_lang === "FR" ?
                                                    "Copier le lien" :
                                                    "Copy link"
                                            }
                                        </Button>
                                       :
                                       <></>
                                    }
                            </div>
                            
                        ))
                    ) : <></>
                }

            </div>
        </Box>
    
    </>

}

export default Nft;