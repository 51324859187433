// PictureSide.jsx
import React from 'react';

const PictureSide = () => {
  return (
    <div>
      <img
            style={{ height: '100vh', resizeMode: 'contain' }}
            src='/imgs/login.jpg'
            alt='logo from the login'
        />
    </div>
  );
};

export default PictureSide;
