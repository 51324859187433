import React from 'react';

import { Box, Strong } from '@radix-ui/themes';

const Legal_header = () => {
    return (
        <>

            <header className="text-gray-600 body-font">

                <Box className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img src="/imgs/koffy_logo.png" width="60px" alt="koffy logo" />
                        <span className="ml-3 text-3xl font-extrabold">Koffy</span>
                        
                    </a>
                    
                    <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
                        <a className="mr-5 hover:text-gray-900" href="/">Acceuil</a>
                    </nav>
                </Box>

            </header>
        </>
    );
}

export default Legal_header;