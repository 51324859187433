import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

import News from './body/News.jsx';
import MainBody from './body/main.jsx';

// This is like a loader
import Wait from './waiting/wait.jsx';
import NoContent from './waiting/noContent.jsx';
import NoContentWeekend from './waiting/weekend.jsx';

import Banner from './banner/banner.jsx';

// get today day in the week
function getToday() {
    const today = new Date();
    const day = today.getDay();
    return day
}

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

function getTodayDate() {
  // date regex test: YYYY-MM-DD
  return new Date().toISOString().slice(0, 10);
}


function rewriteUrl(koffyData) {
  for(let i = 0; i < koffyData.news.length; i++) {
    if(koffyData.news[i].newsraw_illu.startsWith('https://pbs.twimg.com/')){
      koffyData.news[i].newsraw_illu = koffyData.news[i].newsraw_illu = "https://proxyweb.koffy.finance/get?url="+koffyData.news[i].newsraw_illu;
    }
  }
  return koffyData;
}

const Body = ({ userData, time }) => {

    let [koffyData, setKoffyData] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state
  
    useEffect(() => {
      const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
      const token = cookie ? cookie.split('token=')[1] : null;

      // contact the api to get the user data
      fetch("https://kissaten.koffy.finance/page/get", { // wee need to change the url when we will be in production
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            "date": time
        })
      })
      .then(res => res.json())
      .then(data => {
        setKoffyData(data.data);
        setLoading(false);
      })
    }, []);

    const today = getToday();
    
    let todayDate = getTodayDate();
    if(todayDate === time) {
      if(today === 0 || today === 6) {
        return (
          <>
            <NoContentWeekend userData={userData}/>
          </>
        )
      }
    }
    
  
    if(loading) return (
      <>
        <Wait />
      </>
    )

    if(koffyData.news.length === 0) return (
      <>
        <NoContent userData={userData.userData}/>
      </>
    )

    koffyData = rewriteUrl(koffyData);

    return <>

        {
          koffyData.announcement.length > 0 ? <Banner data={koffyData.announcement[0]} userdata={userData}/> : null
        }

        {/* 
            Here we need to cut the page in 2 parts.
            Left side: md-2 -> for the last news
            Right side: md-10 -> for the main content
        */}
        <Grid style={{height: '100%', display: 'flex'}}>

            {
                checkSreenInf750() ? <Box>
                  <Box style={{width: "100%"}} >
                      <News koffyData={koffyData} userData={userData}/>
                  </Box>
                  <Box style={{ width: "100%"}} className="md-10">
                      <MainBody koffyData={koffyData} userData={userData}/>
                  </Box>
                </Box> : <>
                  <Box style={{height: '100%', width: "20%"}} >
                      <News koffyData={koffyData} userData={userData}/>
                  </Box>
                  <Box style={{ height: '100%', width: "80%"}} className="md-10">
                      <MainBody koffyData={koffyData} userData={userData}/>
                  </Box>
                </>
            }

        </Grid>
    </>
}

export default Body;