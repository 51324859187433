import React from 'react';
import { Box, Grid, Text, Link, Button  } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

import Wallet from './Wallet.jsx';

function convertDateFormat(date) {
    // swting the date format from 2022-11-17T06:46:31.000Z to "DD fullmonth YYYY"
    const dateArray = date.split('-');
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2].split('T')[0];
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet","Août","Septembre","Octobre","Novembre","Décembre"];
    return `${day} ${months[month-1]} ${year}`;
}

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const MainHeader = (koffyData) => {

    const data = koffyData.koffyData.koffyData;

    function redirectToWebPage(url) {
        navigator.clipboard.writeText(url).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            alert("Link copied to clipboard")
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    function getRandomString() {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for ( var i = 0; i < 10; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    const token = getRandomString();
    
    if(!data) return <></>

    return <>

        <Box style={{height: '100%', display:"block"}}>
            {/*
                This section is recut in 2 parts:
                main -> 80% of the page -> big image
                right -> 20% of the page -> small image
             */}

            <Box style={{marginLeft: '20px'}}>
                {
                    checkSreenInf750() ? <Text size="6" weight="bold" style={{
                        display: "flex",
                    }}>
                        {
                            koffyData.koffyData.userData.usrsett_lang === "FR" ?
                                "Actualités :" :
                                "Latest news:"
                            }
                        </Text>
                        : <></>
                } <br />

            </Box>

            <Grid style={{height: '100%', display: 'flex', marginLeft: '20px'}}>
                <Box style={{height: '100%', width: checkSreenInf750() ? "100%" : "80%"}} >
                    
                    {/*
                        this section is the biggest part of the page
                        main a big image
                        under this: title, description, button
                    */}
                    
                    {
                        (data.news && data.news.length > 0) ? 
                            <Link href={
                                checkSreenInf750() ?
                                '#'+token
                                :
                                data.news[0].usht_sht
                            } style={{textDecoration: 'none', color: "black"}} target={
                                checkSreenInf750() ?
                                ''
                                :
                                "_blank"
                            }>
                                <Box style={{height: '100%', width: "95%", display: "block"}}>
                                    <img src={data.news[0].newsraw_illu} alt='test' height='90%' style={{borderRadius: "3px"}} />
                                    <br />
                                    <Text size="2" className='rt-Text' style={{
                                        color: '#a4a4a4',
                                    }} weight='bold'>{convertDateFormat(data.news[0].newspro_processts)}</Text><br />
                                    <Text as="p" size="2" color='gray'>
                                        {data.news[0].newsraw_source}
                                    </Text>
                                    <Text size="6" weight="bold" style={{width: "80%"}}>{data.news[0].newspro_trlstitle}</Text><br />
                                    <Text style={{width: "80%"}}>
                                        {data.news[0].newspro_body}
                                    </Text>

                                </Box>
                                {
                                        checkSreenInf750() ? 
                                        <Button onClick={() => redirectToWebPage(data.news[0].newsraw_link)} style={{marginTop: "10px", backgroundColor: "#000000"}}>
                                            {
                                                koffyData.koffyData.userData.usrsett_lang === "FR" ?
                                                    "Copier le lien" :
                                                    "Copy link"
                                            }
                                        </Button>
                                        : (<></>)
                                    }
                            </Link>
                        : (<></>)
                    }

                </Box>

                {
                    checkSreenInf750() ? <></> :
                    <Box style={{height: '100%', width: "20%", marginLeft: '20px'}} className="md-10">
                        <Wallet userData={koffyData}/>
                    </Box>
                }

                
            </Grid>
            
        </Box>
    
    </>
}

export default MainHeader;