import React, { useState, useEffect} from 'react';
import { Box, Grid } from '@radix-ui/themes';


// This is like a loader
import Wait from '../compo/waiting/wait.jsx';
import NoContent from '../compo/waiting/noContent2.jsx';

import ArticlesSimple from './art.jsx';

function getArtCount() {
  let containerWidth = window.innerWidth-140
  // Assuming a minimum width of 250px for each element
  const minElementWidth = 350;

  // Calculate the maximum number of elements that can fit in the container
  const maxCount = Math.floor(containerWidth / minElementWidth);

  if(maxCount === 0) return 1

  return maxCount;
}

const LibraryPage = (userData) => {

    let search = ""
    try {
        search = decodeURIComponent(document.location.href.split('?s=')[1].split("&")[0])
    } catch (error) {}

    let [koffyData, setKoffyData] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state
  
    useEffect(() => {
      const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
      const token = cookie ? cookie.split('token=')[1] : null;

      // contact the api to get the user data
      fetch("https://kissaten.koffy.finance/library/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            "search": search
        })
      })
      .then(res => res.json())
      .then(data => {
        setKoffyData(data.data);
        setLoading(false);
      })
    }, []);
  
    if(loading) return (
      <>
        <Wait />
      </>
    )


    try {
        if(koffyData && koffyData.length === 0) return (
            <>
              <NoContent userData={userData.userData}/>
            </>
        )
    } catch (error) {}

    // create pack by 3
    let packlist = [];
    
    let count = getArtCount()
    for (let i = 0; i < koffyData.length; i += count) {
        // Slice the data array to get a subarray of three elements
        let subarray = koffyData.slice(i, i + count);
    
        // Add the subarray to the packlist array
        packlist.push(subarray);
    }
  

    return <>
        {/* 
            Here we need to cut the page in 2 parts.
            Left side: md-2 -> for the last news
            Right side: md-10 -> for the main content
        */}
        <Grid style={{height: '100%', display: 'block'}}>
          
            {
              packlist && packlist.map((data, index) => (
                <Box style={{height: '100%', width: "100%", marginRight: '20px', marginLeft: '20px', marginTop: '20px', display: "flex"}} className="md-10">
                  {
                    data && data.map((data, index) => (
                      <ArticlesSimple articleData={data} />
                    ))
                  }
                </Box>
              ))
            }
        </Grid>
    </>
}


export default LibraryPage