import React from 'react';
import { Box, Text, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

/******* FUCKING APP LIKE */
function getScreenSizeForTextSize() {
    if(window.innerWidth < 768) {
        return '90%'
    } else {
        return '25%'
    }
}


const onPc = (userData) => {

    function deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
    }
    

    const disconnect = () => {
        deleteCookie("token")
        document.location.href = "/"
    }
    
    return <>
    {/* box full heigth full width with an image in the center */}

       <Box style={{ backgroundColor: 'white', height: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "40px" }}>
           <Box style={{ display: "block", height: '25%', width: getScreenSizeForTextSize(), justifyContent: "center", marginBottom: "40px"}}>

                <br />
                <br />
                <br />
                <br />
               <Text size='3' style={{ color: 'black', marginBottom: "40px" }} weight="bold">
                    La gestion de votre subscription n'est possible que depuis votre pc
               </Text>

                <Button onClick={disconnect} style={{ backgroundColor: '#000000', color: 'white', width: "100%" }}>
                    Déconnexion
                </Button>
           </Box>
       </Box>
</>
}

export default onPc