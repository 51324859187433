import React from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';



const noContent = (userData) => {
    
    return <>
         {/* box full heigth full width with an image in the center */}

        <Box style={{ backgroundColor: 'white', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box style={{ display: "block"}}>
                <img src="https://media.tenor.com/1avzDctvMzoAAAAC/coffee-need.gif" alt="loading" />
            
                <Box style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Text size='6' style={{ color: 'black' }} weight="bold">
                        {
                            userData.userData.usrsett_lang === "FR" ?
                                "Contenu non trouvé pour cette recherche" :
                                "Content not found for this search"
                        }
                    </Text>
                </Box>
            </Box>
        </Box>
    </>

}


export default noContent;