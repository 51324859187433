import React, { useState, useEffect } from 'react';
import { Flex, Text, Button, Box, Heading, Popover, Link, HoverCard, Strong, IconButton, TextField } from '@radix-ui/themes';
import { FileTextIcon, LinkBreak2Icon, MagnifyingGlassIcon, GearIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';

import Lib from './headers/lib.jsx';
import Discord from './headers/discord.jsx';
import Rss from './headers/rss.jsx';
import Espresso from './headers/espresso.jsx';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function getprettyDate(lang) {
    // get today's date in french
    var today = new Date();
    if(lang === "FR") {
        return today.toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    } else {
        return today.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
}

function getScreenWidth() {
    return window.innerWidth;
}

function display(base) {
    if(getScreenWidth() < 1000) {
        return "100%";
    }
    return base;
}

function display50(base) {
    if(getScreenWidth() < 1000) {
        return "48%";
    }
    return base;
}

function marginTopSize() {
    if(getScreenWidth() < 1546) {
        return "10px";
    }
    return 0;
}

/**
 * This is the header of the logged user
 * This is used to diplay the main informations
 */
const Header = (userData) => {

    function deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
    }
    

    const disconnect = () => {
        deleteCookie("token")
        document.location.href = "/"
    }

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
      setSearchTerm(event.target.value.trim());
    };
  
    useEffect(() => {
      const delayTimer = setTimeout(() => {
        // Check if the search term is not empty
        if (searchTerm !== '') {
            // Redirect to the /library route with the search query
            history.push(`/library?s=${encodeURIComponent(searchTerm)}`);
            // reload page
            window.location.reload();
        }
      }, 1500);
      // Clear the timer on every key press to reset the delay
      return () => clearTimeout(delayTimer);
    },  [searchTerm, history]);

    return <>
        <header className="text-black body-font">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-2/5 flex-wrap items-center text-base" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    
                    <Link href="/"  color='gray' style={{ width: display50('auto'), marginTop: marginTopSize(), marginRight: "1%" }}>
                        <Button variant="outline" style={{marginRight: "1em",  width: display('auto')}}>
                            <FileTextIcon width="25" height="18" /> <Text>
                                {' '}
                                <HoverCard.Root>
                                    <HoverCard.Trigger>
                                    <Link href="/">
                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                            "Edition" :
                                            "Edition"
                                        }
                                    </Link>
                                    </HoverCard.Trigger>
                                    <HoverCard.Content>
                                    <Flex gap="4">
                                        <Box>
                                            <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                                {
                                                    userData.userData.usrsett_lang === "FR" ?
                                                    "Retour à l'édition du " :
                                                    "Back to the "
                                                }
                                                <Strong>{getprettyDate(userData.userData.usrsett_lang)}</Strong> 📰
                                            </Text>
                                        </Box>
                                    </Flex>
                                    </HoverCard.Content>
                                </HoverCard.Root>{' '}
                            </Text>
                        </Button>
                    </Link>

                    <Rss userData={userData.userData} style={{ width: display50('auto'), marginTop: marginTopSize(), marginLeft: 40 }}/>

                    <Lib userData={userData.userData} style={{ width: display50('auto'), marginTop: marginTopSize(), marginRight: 10 }}/>
                                      

                    <TextField.Root style={{ width: "auto", marginTop: 10, marginLeft: '0%', marginRight: 20 }} size="2"
                            onChange={handleSearch} placeholder={
                                userData.userData.usrsett_lang === "FR" ?
                                "Librairie d’articles":
                                "Articles library"
                            }>
                        <TextField.Slot pr="1">
                            <IconButton size="2" variant="ghost">
                            <Text>
                            {' '}
                                <HoverCard.Root>
                                <HoverCard.Trigger>
                                    <Link href="#">
                                    <QuestionMarkCircledIcon height="16" width="16" />
                                    </Link>
                                </HoverCard.Trigger>
                                <HoverCard.Content>
                                    <Flex gap="4">
                                    <Box>
                                        <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                            {
                                                userData.userData.usrsett_lang === "FR" ?
                                                (<>La <Strong>Librairie 📚</Strong>, c'est notre <i>grande</i> bibliothèque d'articles. Vous pouvez y chercher <Strong>tous les articles</Strong> que nous avons <u>méticuleusement</u> sélectionnés pour <Strong>vous</Strong> !</>):
                                                (<>The <Strong>Library 📚</Strong> is our <i>big</i> articles library. You can search for <Strong>all the articles</Strong> that we have <u>meticulously</u> selected for <Strong>you</Strong> !</>)
                                            }
                                        </Text>
                                    </Box>
                                    </Flex>
                                </HoverCard.Content>
                                </HoverCard.Root>{' '}
                            </Text>
                            </IconButton>
                        </TextField.Slot>
                        <TextField.Slot>
                            <MagnifyingGlassIcon height="16" width="16" />
                        </TextField.Slot>
                    </TextField.Root>

                    <Espresso userData={userData.userData} style={{ width: "auto", marginTop: 10, height: 16 }}/>

                </nav>

                <a className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-black lg:items-center lg:justify-center mb-4 md:mb-0" href='/'>
                    <img src="/imgs/koffy_logo.png" width="50px" alt='koffy logo'/>
                </a>

                <div className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto" style={{width: display('auto')}}>

                        {
                            getScreenWidth() > 750 ?
                                <Discord userData={userData.userData}/>:
                                <></>
                        }

                        <Link href="/settings" color='gray' style={{ width: display('auto'), marginTop: marginTopSize() }}>
                            <Button variant="outline" style={{marginRight: "1em",  width: display('auto')}}>
                                <GearIcon width="25" height="18" /> <Text>
                                    {' '}
                                    <HoverCard.Root>
                                        <HoverCard.Trigger>
                                        <Link href="/settings">
                                            {
                                                userData.userData.usrsett_lang === "FR" ?
                                                "Réglages" :
                                                "Settings"
                                            }
                                        </Link>
                                        </HoverCard.Trigger>
                                        <HoverCard.Content>
                                        <Flex gap="4">
                                            <Box>
                                                <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                                    {
                                                        userData.userData.usrsett_lang === "FR" ?
                                                        (<>Les reglages ⚙️ permettent de pouvoir <Strong>customiser</Strong> votre experience sur <Strong>Koffy</Strong>. Ils vous permettent également de pouvoir <Strong>changer </Strong> votre <Strong>mot de passe</Strong> que vous avez oublié <i>par exemple</i>.</>) :
                                                        (<>The settings ⚙️ allow you to <Strong>customize</Strong> your experience on <Strong>Koffy</Strong>. It also allows you to <Strong>change</Strong> your <Strong>password</Strong> that you forgot <i>for example</i>.</>)
                                                    }
                                                </Text>
                                            </Box>
                                        </Flex>
                                        </HoverCard.Content>
                                    </HoverCard.Root>{' '}
                                </Text>
                            </Button>
                        </Link>

                        <Popover.Root style={{ width: display('auto'), marginTop: marginTopSize() }}>
                            <Popover.Trigger style={{ width: display('auto'), marginTop: marginTopSize() }}>
                                <Button variant="soft"  color='gray'>
                                <LinkBreak2Icon width="16" height="16" />
                                    
                                    <Text>
                                        {' '}
                                        <HoverCard.Root>
                                            <HoverCard.Trigger>
                                            <Link href="#">
                                                {
                                                    userData.userData.usrsett_lang === "FR" ?
                                                        "Déconnexion" :
                                                        "Disconnect"
                                                }
                                            </Link>
                                            </HoverCard.Trigger>
                                            <HoverCard.Content>
                                            <Flex gap="4">
                                                <Box>
                                                    <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                                        {
                                                            userData.userData.usrsett_lang === "FR" ?
                                                                (<>La <Strong>déconnexion</Strong> vous permet de vous déconnecter de votre compte <Strong>Koffy</Strong>.<br/> <u>Bon a savoir</u>: vous pouvez vous reconnecter <Strong>quand vous voulez</Strong> !</>) :
                                                                (<>The <Strong>disconnect</Strong> allows you to disconnect from your <Strong>Koffy</Strong> account.<br/> <u>Good to know</u>: you can reconnect <Strong>whenever you want</Strong> !</>)
                                                        }
                                                    </Text>
                                                </Box>
                                            </Flex>
                                            </HoverCard.Content>
                                        </HoverCard.Root>{' '}
                                    </Text>

                                </Button>
                            </Popover.Trigger>
                            <Popover.Content style={{ width: 300 }}>
                                <Heading size="2" mb="1">
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                            "Etes-vous sûr de vouloir vous déconnecter ?" :
                                            "Are you sure you want to disconnect ?"
                                    }
                                </Heading>

                                <Flex direction="column" align="stretch">
                                    <Popover.Close>
                                        <Button size="1" variant="soft" color='crimson' onClick={disconnect}>
                                        <LinkBreak2Icon width="16" height="16" /> 
                                            {
                                                userData.userData.usrsett_lang === "FR" ?
                                                    "Oui" :
                                                    "Yes"
                                            }
                                        </Button>
                                    </Popover.Close>
                                </Flex>
                            </Popover.Content>
                        </Popover.Root>

                </div>
            </div>
        </header>

    
    </>

}

export default Header;