import React from 'react';

const Me = (userData) => {
    return (
        <>
            <p>
                Hello {userData.userData.usr_mail} !
            </p>
        </>
    );
}

export default Me;
