import React, { useEffect } from 'react';
import { Box, Text, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

/******* FUCKING APP LIKE */
function getScreenSizeForTextSize() {
    if(window.innerWidth < 768) {
        return '90%'
    } else {
        return '25%'
    }
}

async function sendVerificationToken(user_token) {
    const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
    const token = cookie ? cookie.split('token=')[1] : null;
    // contact the api to get the user data
    fetch("https://kissaten.koffy.finance/user/email_token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        "token": user_token
      })
    })
    .then(data => {
        alert("Votre email est vérifié")
        window.location.href = "/"
    })
}


const EmailValidation = () => {
    
    useEffect(() => {
        const l = window.location.href
        if(l.includes('/verify/')){
            // send the verification token
            const token = (l.split('/verify/'))[1]
            sendVerificationToken(token)   
        }
    }, []);

    const resend_email = async () => {
        const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
        const token = cookie ? cookie.split('token=')[1] : null;
        // contact the api to post the user data
        fetch("https://kissaten.koffy.finance/settings/resend_email_token", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        })
        .then(res => res.text())
        .then(data => {
          alert("Un nouvel email vous a été envoyé")
        })
    }

    return <>
         {/* box full heigth full width with an image in the center */}

            <Box style={{ backgroundColor: 'white', height: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "40px" }}>
                <Box style={{ display: "block", height: '25%', width: getScreenSizeForTextSize(), justifyContent: "center", marginBottom: "40px"}}>
                    <img src="/imgs/gif/email.gif" alt="loading" style={{
                        width: '100%',
                        objectFit: 'cover',
                    }} />
                
                    <Text size='6' style={{ color: 'black' }} weight="bold">
                        Merci de bien vouloir vérifier votre email
                    </Text>
                    <br />
                    <br />
                    <Text size='3' style={{ color: 'black', marginBottom: "40px" }} weight="bold">
                        Un email de vérification vous a été envoyé. Veuillez cliquer sur le lien dans l'email pour vérifier votre compte.
                    </Text>
                    <br />
                    <br />
                    <Button onClick={resend_email} style={{ backgroundColor: '#000000', color: 'white', width: "100%" }}>
                        Renvoyer l'email
                    </Button>
                </Box>
            </Box>
    </>

}

export default EmailValidation;