import React from 'react';
import { Text, Box, Grid} from '@radix-ui/themes';


function getCurrentYear() {
    return new Date().getFullYear();
}

function getScreenSizeForColCount() {
    if(window.innerWidth < 768) {
        return "1"
    } else {
        return "3"
    }
}


const Footer = (userData) => {

    if(!userData || !userData.userData) {
        userData = {
            userData: {
                usrsett_lang: "FR"
            }
        }
    }

    return (
        <Box style={{
            backgroundColor: "black",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            marginTop: "20px"
        }}>
            <Box className="flex mb-8 text-white" style={{
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box className="mb-10 mx-10" style={{
                    marginTop: "40px",
                    alignItems: "center",
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                }}>
                    <img src='/imgs/koffy_logo_white.png' alt="Logo" style={{
                        maxHeight: "50px",
                        marginTop: "20px"
                    }}/>
                </Box>
            </Box>
    
            <Box className="flex mb-8 text-white" style={{
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Grid columns={getScreenSizeForColCount()} gap="2">
                    <Box >
                        <a href="/legal">
                            <Text>Legal</Text>
                        </a>
                    </Box>
                    <Box >
                        <a href="/cgu">
                            <Text>
                                {
                                    userData.userData.usrsett_lang === "EN" ?
                                        "Terms of service" :
                                        "Conditions générales d'utilisation"
                                }
                            </Text>
                        </a>
                    </Box>
                    <Box >
                        <a href="/cgv">
                            <Text>
                                {
                                    userData.userData.usrsett_lang === "EN" ?
                                        "General subscription conditions" :
                                        "Conditions générales de vente"
                                }
                            </Text>
                        </a>
                    </Box>
                </Grid>
            </Box>
    
            <Box className="flex mb-8 text-white" style={{
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box className="mb-4 mx-4" style={{
                    marginTop: "40px",
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                }}>
                    <img decoding="async" loading="lazy" alt="gmo_cyberseal" id="csi_siteseal_image" width="115" height="57" src="/imgs/gmo_cyberseal.png" style={{background: "white", borderRadius: "7px"}} />
                    <img decoding="async" loading="lazy" alt="letslock" id="letslock" width="115" height="57" src="/imgs/letslock.svg" style={{marginLeft: "15px"}} />
                </Box>
                {
                    window.addEventListener('load', () => {
                        let s = document.createElement("script");
                        s.src = "https://gmo-cybersecurity.com/siteseal/siteseal.js"
                        document.body.appendChild(s);
                    })
                }
            </Box>
    
            <Box className="text-white font-montserrat text-sm" style={{
                height: '80px'
            }}>
                © 2022 - {getCurrentYear()} Koffy — Made with ❤️ and ☕
            </Box>
        </Box>
    );    

}


export default Footer