import React from 'react';
import { Box, Text, Link } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';



function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const Jobs = (koffyData) => {

    const data = koffyData.koffyData.koffyData;

    if(!data) return <></>

    return <>

        <Box style={{height: '100%', display:"block", marginLeft: '20px', marginTop: "40px",  marginRight: '40px'}}>    

            {
                (data.jobs && data.jobs.length > 1) ? (<><Text size="6" weight="bold">
                    {
                        koffyData.koffyData.userData.usrsett_lang === "FR" ?
                            "Offres d’emploi" :
                            "Jobs"
                    }
                </Text>
                <Text size="1">
                    &nbsp;by OnchainJobs
                </Text></>) : <></>
            }


            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                    {data.jobs.length > 1 ? (
                        <>
                        <th scope="col" className="px-6 py-3 text-left text-large font-black text-black uppercase tracking-wider">Titre</th>
                        <th scope="col" className="px-6 py-3 text-left text-large font-black text-black uppercase tracking-wider">Société</th>
                        <th scope="col" className="px-6 py-3 text-left text-large font-black text-black uppercase tracking-wider">Type</th>
                        <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Access</span>
                        </th>
                      </>
                    ) : ( <></> )}
                    </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">

                      {data.jobs.length > 1 ? (
                    data.jobs.map((job, index) => (
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                    <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">{job.jobs_title}</div>
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{job.jobs_company}</div>
                            </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{job.jobs_type}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <Link href={

                                    checkSreenInf750() ?
                                    <></> :
                                    job.jobs_link

                                } className="text-black" target='_blank'>Voir l’offre d’emploi</Link>
                            </td>
                        </tr>
                    ))) : ( <></> )
                }
                </tbody>
            </table>
        
        </Box>
    
    </>
}

export default Jobs;