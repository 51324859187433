import React from 'react';
import { Flex, Text, Button, Box, Link, HoverCard, Strong } from '@radix-ui/themes';
import { DiscordLogoIcon } from '@radix-ui/react-icons';


function display(base) {
    if(getScreenWidth() < 1000) {
        return "100%";
    }
    return base;
}

function getScreenWidth() {
    return window.innerWidth;
}


function marginTopSize() {
    if(getScreenWidth() < 1546) {
        return "10px";
    }
    return 0;
}


const discord = (userdata) => {
    return (
        <Link href="https://discord.gg/WvTz3bxHbX" target='_blank' color='gray' style={{ width: display('auto'), marginTop: marginTopSize() }}>
            <Button variant="outline" style={{marginRight: "1em",  width: display('auto')}}>
                <DiscordLogoIcon width="25" height="18" /> <Text>
                    {' '}
                    <HoverCard.Root>
                        <HoverCard.Trigger>
                        <Link href="https://discord.gg/WvTz3bxHbX" target='_blank'>
                            Discord
                        </Link>
                        </HoverCard.Trigger>
                        <HoverCard.Content>
                        <Flex gap="4">
                            <Box>
                                <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                    {
                                        userdata.userData.usrsett_lang === "FR" ?
                                        (<>Notre communauté Discord est un endroit idéal pour discuter avec d'autres membres de la communauté, partager des idées et obtenir de l'aide. <br /> <br /> Rejoignez-nous dès maintenant ! <Link href="https://discord.gg/WvTz3bxHbX" target='_blank'>Clique ici</Link></>) :
                                        (<>Our Discord community is a great place to chat with other community members, share ideas, and get help. <br /> <br /> Join us now! <Link href="https://discord.gg/WvTz3bxHbX" target='_blank'>Click here</Link></>)
                                    }
                                </Text>
                            </Box>
                        </Flex>
                        </HoverCard.Content>
                    </HoverCard.Root>{' '}
                </Text>
            </Button>
        </Link>
    )
}

export default discord;