import React from 'react';
import { Box, Grid, Text, Link, Button  } from '@radix-ui/themes';

import { } from '@radix-ui/react-icons';


function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const Banner = (data) => {

    const size = checkSreenInf750() ? "100%" : "60%";

    // crate a full width box, 10px height, with a background color blue
    return <Box style={{width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "15px"}}>
        <Box style={{ backgroundColor: '#ebd0a3', minHeight: "35px", width: size, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px'}}>
            <Text style={{width: '100%', textAlign: 'center'}}>
                <Link href={data.data.announcement_link} style={{color: "black"}} target='_blank'>
                    {
                        data.userdata.usrsett_lang === "FR" ?
                            data.data.announcement_text_fr:
                            data.data.announcement_text_en
                    }
                </Link>
            </Text>
        </Box>
    </Box>


}

export default Banner;
