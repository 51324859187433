import React from 'react';
import { Box, Text, Link } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

export const NewsUI = (newsData) => {


    function redirectToWebPage(url) {
        navigator.clipboard.writeText(url).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            alert("Link copied to clipboard")
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    function getRandomString() {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for ( var i = 0; i < 10; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    const token = getRandomString();

    return <>
        {/* 
            the news element: 
            top left: a little timestamp
            center: the title
            bottom: a separator bar
        */}        

        <Box className='rt-Box' style={{display: "block"}} id={token}>
            <Text size="2" className='rt-Text' style={{
                color: '#a4a4a4',
            }} >
                {/* Show the newsData.time [depressied since echo ask] */}
                
                {/* <Strong>{newsData.time}</Strong> */}
            </Text>
            <Text size="3" className='rt-Text'>
                {/* Show the newsData.title */}
                {
                    checkSreenInf750() ?
                    <Link href={"#"+token} onClick={() => redirectToWebPage("https://koffy.finance/shortlink?code="+newsData.code)} rel="noreferrer" style={{
                        color: "black"
                    }}>
                        {newsData.title}
                    </Link>
                    :
                    <Link href={"https://koffy.finance/shortlink?code="+newsData.code} target='_blank' rel="noreferrer" style={{
                        color: "black"
                    }}>
                        {newsData.title}
                    </Link>
                }
            </Text>
            <Box style={{
                height: '1px',
                width: '100%',
                margin: '10px 0px 10px 0px',
                backgroundColor: '#dadada'
            }} className='rt-Box'></Box>
        </Box>

    </>
}

export default NewsUI;