import React from 'react';

import { Box } from '@radix-ui/themes';

import Legal_header from './block/header.jsx';
import Legal_footer from './block/footer.jsx';

const Legal = () => {
    return (
        <>

            <Legal_header />

            <section>
            <Box className="max-w-screen-xl mx-auto p-8">
                <h2 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 text-gray-900 mb-12">
                Légal 🇫🇷
                </h2>
                <ul className="flex items-start gap-8 flex-wrap">
                    <li className="w-2/5">
                        <p className="text-lg font-medium leading-6 text-gray-900">
                        L'éditeur du site Koffy.Finance est :
                        </p>
                        <p className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            Koffy.Finance <br />
                            Siège social - Montreuil 93100, France<br />
                            Siret - 90058667800014
                        </p>
                        </p>
                    </li>
                    <li className="w-2/5">
                        <p className="text-lg font-medium leading-6 text-gray-900">
                        Contact :
                        </p>
                        <p className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            Alexandre A. <br />
                            contact@koffy.finance<br /><br />
                        </p>
                        </p>
                    </li>
                    <li className="w-2/5">
                        <p className="text-lg font-medium leading-6 text-gray-900">
                        Hébergeur :
                        </p>
                        <p className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            Koffy.finance est hébergé par Aqua Ray S.A.S<br />
                            Siège social - 14 rue Jules Vanzuppe 94200 IVRY-SUR-SEINE<br /> FRANCE<br />
                            SIREN - RCS Créteil 447 997 099<br /><br />

                            Koffy.finance est hébergé par Hetzner<br />
                            Datacenter d'Helsinki - Huurrekuja 10, 04360 Tuusula<br /> FINLANDE<br />
                            VAT - DE 812871812
                        </p>
                        </p>
                    </li>
                    <li className="w-2/5">
                        <p className="text-lg font-medium leading-6 text-gray-900">
                        Administation du site :
                        </p>
                        <p className="text-base leading-6 text-gray-500">
                            Webmaster - Alexandre A.<br />
                            Responsable de publication - Alexandre A.<br /><br />

                            Koffy.finance possède sa propre infrastructure : serveurs, routeurs (etc). <br />Responsable technique -
                            Alexandre A.
                        </p>
                    </li>
                    <li className="w-2/5">
                        <p className="text-lg font-medium leading-6 text-gray-900">
                        Propriété intellectuelle
                        </p>
                        <p className="text-base leading-6 text-gray-500">
                            A l'exception des contenus clairement identifiés comme appartenant à nos partenaires, des tiers qui
                            peuvent apparaitre sur Koffy.finance, tous les éléments distinctifs (logo, textes, nom de domaine, marque)
                            qui peuvent apparaitre sur le site (https://koffy.finance) demeurent notre propriété exclusive. Toute
                            représentation de ces éléments, l'emploi de notre nom, et toute association est formellement interdite
                            sans notre accord écrit préalable, tout usage nommé ci-dessus est illégal et peut donner suite à des
                            poursuites judiciaires.
                        </p>
                    </li>
                </ul>
            </Box>
            </section>

            <section>
            <Box className="max-w-screen-xl mx-auto p-8">
                <h2 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 text-gray-900 mb-12">
                Legal 🇬🇧
                </h2>
                <ul className="flex items-start gap-8 flex-wrap">
                <li className="w-2/5">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                    The editor of Koffy.Finance is :
                    </p>
                    <p className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                        Koffy.Finance <br />
                        Headquarter - Montreuil 93100, France<br />
                        Siret - 90058667800014
                    </p>
                    </p>
                </li>
                <li className="w-2/5">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                    Contact :
                    </p>
                    <p className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                        Alexandre A. <br />
                        contact@koffy.finance
                    </p>
                    </p>
                </li>
                <li className="w-2/5">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                    Hosting company :
                    </p>
                    <p className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                        Koffy.finance is hosted by Aqua Ray S.A.S<br />
                        Headquarter - 14 rue Jules Vanzuppe 94200 IVRY-SUR-SEINE - FRANCE<br />
                        SIREN - RCS Créteil 447 997 099<br /><br />

                        Koffy.finance is hosted by Hetzner<br />
                        Helsinki's Datacenter - Huurrekuja 10, 04360 Tuusula<br /> FINLAND<br />
                        VAT - DE 812871812
                    </p>
                    </p>
                </li>
                <li className="w-2/5">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                    Website administration :
                    </p>
                    <p className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                        Webmaster - Alexandre A.<br />
                        Publication manager - Alexandre A.<br /><br />

                        Koffy.finance has its own infrastructure: servers, routers (etc). <br />Responsible for the administration -
                        Alexandre A. </p>
                    </p>
                </li>
                <li className="w-2/5">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                    Intellectual property
                    </p>
                    <p className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                        With the exception of content clearly identified as belonging to our partners, third parties may appear on
                        Koffy.finance. All distinctive elements (logo, texts, domain name, brand) that may appear on the site
                        https://koffy.finance remain our exclusive property. Any representation of these elements, the use of our
                        name, and any association is formally forbidden without our prior written agreement, any use named above
                        is illegal and may result in legal proceedings.
                    </p>
                    </p>
                </li>
                </ul>
            </Box>
            </section>


            <Legal_footer />
            

        </>
    );
}

export default Legal;
