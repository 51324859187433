import React from 'react';
import { Box, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';
import { createPopup } from '../../../js/popup'


async function contactApi(path, body) {
    try {
        const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
        const token = cookie ? cookie.split('token=')[1] : null;
        const req = await fetch("https://kissaten.koffy.finance"+path, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
        return await req.json()
    } catch (error) {
        return null
    }
}

const Settings_stripe = (userData) => {

    const u = userData.userData


    const openStripe = async () => {
        // get the url with the api
        const data = await contactApi("/subscribe/manage")
        if(data.error){
            if(data.error === "bad_request_no_subscription") {
                createPopup("You don't have a subscription")
            }
            return
        }
        // open the url in a new tab
        if(data.url) {
            window.location.href=data.url
        }
    }

    return <>
    
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20px", marginRight: "10%", marginLeft: "10%" }}>
            <Button onClick={openStripe}> 
                {
                    u.usrsett_lang === "FR" ? 
                        "Ouvrir Stripe" : 
                        "Open Stripe"
                }
            </Button>
        </Box>
    
    
    </>

}

export default Settings_stripe;