import React, { useEffect, useState } from 'react';
import Error404 from '../errors/404.jsx';
import { Box, Text, Card, Inset, Link, Button  } from '@radix-ui/themes';


import Header from './compo/header.jsx';
import Footer from '../utils/footer.jsx'

import EspressoPlayer from './espresso/index.jsx';

/**
 * This page is the time machine page
 * This is used to diplay the main informations
 * but from a specific date
 */
const Espresso = (userData) => {

    // get the curent url
    const date = window.location.href.split("/")[4];
    console.log(date);
    
    // get espresso podcast for the date

        // fetch all the rss feed
    const [espressoData, setEspressoData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    //show the current poath
    console.log(window.location.pathname);
  
    useEffect(() => {
      // contact the api to get the user data
      fetch("https://kissaten.koffy.finance/espresso", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({date: date})
      })
      .then(res => res.json())
      .then(data => {
        setEspressoData(data);
        setLoading(false);
      })
      .catch(error => {
        // Handle fetch errors
        console.error('Error fetching user data:', error);
        setLoading(false);
      });
    }, []);
    
  
    if(loading) return (
        <>
            <section
                className="pt-24 md:mt-0 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-48 md:px-12 px-4 bg-secondary">
                <div className="md:flex-1 md:mr-10">
                    <h1 className="font-pt-serif text-7xl font-black mb-7">
                        Loading Expresso 
                    </h1>
                    <p className="font-pt-serif text-xl font-normal mb-7">
                        Merci de patienter quelques instants, nous preparons votre expresso du jour. <br/>
                        Celui si sera disponible dans quelques instants.
                    </p>
                    <div className="font-montserrat">
                        <Link to="/"className="px-6 py-4 border-2 border-black border-solid rounded-lg">
                            Revenir à la page d'accueil
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )


    if(espressoData.length === 0) {
        return <Error404/>
    }

    return <>
        <Header userData={userData.userData}/>

        <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">
                        Podcast - Espresso du {espressoData[0].pod_date ? espressoData[0].pod_date : "2022-01-01"}
                    </span>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500"> Koffy - Since 2022 </span>
                </div>
            </div>

        <Box style={{padding: '50px'}}>
            <EspressoPlayer data={espressoData}/>
        </Box>
        
        <Footer/>
    </>
}

export default Espresso;
