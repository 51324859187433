import React from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';


let arr = [
    "https://media.tenor.com/wxv5Qz3ClxkAAAAC/fff.gif",
    "https://media.tenor.com/V2uun6CKk6cAAAAC/coffee-moccamaster.gif",
    "https://media.tenor.com/hN6Cm8u7hgUAAAAC/cup-of-coffee-coffee.gif",
    "https://media.tenor.com/h_dq6SHKHDkAAAAd/greys-anatomy-izzie-stevens.gif",
    "https://media.tenor.com/R0Uszm1ltnAAAAAC/brewing-coffee-valorant.gif"
]

function getRandomGif() {
    // Check if the array is not empty
    if (arr.length === 0) {
      return undefined; // or any other value that makes sense in your context
    }
  
    // Generate a random index within the array's length
    const randomIndex = Math.floor(Math.random() * arr.length);
  
    // Return the element at the random index
    return arr[randomIndex];
}
  

const Wait = (koffyData) => {


    return <>
         {/* box full heigth full width with an image in the center */}

        <Box style={{ backgroundColor: 'white', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box style={{ display: "block"}}>
                <img src={getRandomGif()} alt="loading" />
            
                <Text size='6' style={{ color: 'black' }} weight="bold">
                    Brewing coffee...
                </Text>
            </Box>
        </Box>
    </>

}


export default Wait;