// Function to open the popup

function openPopup() {
    document.getElementById("popup").style.display = "block";
}

function setPopupContent(text) {
    document.getElementById("popup-content-text").innerHTML = text;
}

export function createPopup(text) {
    setPopupContent(text);
    openPopup();
}