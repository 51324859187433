import React, { useEffect } from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

/******* FUCKING APP LIKE */
function getScreenSizeForTextSize() {
    if(window.innerWidth < 768) {
        return '90%'
    } else {
        return '25%'
    }
}


async function reload() {
    document.location.href="/"
}


const Wait = (userData) => {

    useEffect(() => {
        setTimeout( () => {
            reload()
        }, 1000 * 60 * 6)
    }, []);

    return <>
    {/* box full heigth full width with an image in the center */}

       <Box style={{ backgroundColor: 'white', height: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "40px" }}>
           <Box style={{ display: "block", height: '25%', width: getScreenSizeForTextSize(), justifyContent: "center", marginBottom: "40px"}}>
               <img src="/imgs/gif/waiting.gif" alt="loading" style={{
                   width: '100%',
                   objectFit: 'cover',
               }} />
           
               <Text size='6' style={{ color: 'black' }} weight="bold">
                    Nous nous occupons de votre commande.
               </Text>
               <br />
               <br />
               <Text size='3' style={{ color: 'black', marginBottom: "40px" }} weight="bold">
                    Vous recevrez un email de confirmation dans les prochaines minutes. Si vous ne le recevez pas, merci de vérifier vos spams.
               </Text>
               <br />
               <br /><Text size='3' style={{ color: 'black', marginBottom: "40px" }} weight="bold">
                    L'action peut prendre jusqu'à 5 minutes.
               </Text>
               <br />
               <br />
               <Text size='3' style={{ color: 'black', marginBottom: "40px" }} weight="bold">
                    Un problème ? Contactez-nous à l'adresse suivante : contact@koffy.finance
               </Text>
           </Box>
       </Box>
</>
}

export default Wait