import React from 'react';

import { Box, Strong } from '@radix-ui/themes';

import Legal_header from './block/header.jsx';
import Legal_footer from './block/footer.jsx';

const CGV = () => {
    return (
        <>

            <Legal_header />

            <section>
                <Box className="max-w-screen-xl mx-auto p-8">
                <h2 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 text-gray-900 mb-12">
                    Conditions générales de vente 🇫🇷
                </h2>
                <p className="text-lg leading-6 text-gray-900">
                <br /><br /><Strong>ARTICLE 1 - Champ d'application</Strong><br /><br />
                Les présentes Conditions Générales de Vente (dites « CGV ») s'appliquent, sans restriction ni
                réserve à tout achat des services de suivants :
                Abonnement à la plateforme.﻿
                tels que proposés par le Prestataire aux clients non professionnels (« Les Clients ou le
                Client ») sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>.
                <br /><br />
                Les caractéristiques principales des Services sont présentées sur le site internet <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>.
                Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix
                et l'achat d'un Service est de la seule responsabilité du Client.
                Ces CGV sont accessibles à tout moment sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> et prévaudront sur
                toute autre document.
                Le Client déclare avoir pris connaissance des présentes CGV et les avoir acceptées en cochant
                la case prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne du
                site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>.
                Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire
                constituent la preuve de l'ensemble des transactions conclues avec le Client.<br /><br />
                Les coordonnées du Prestataire sont les suivantes :<br />
                Alexandre A.<br />
                Montreuil 93100 FRANCE<br />
                Numéro d'immatriculation : 90058667800014<br />
                mail : <Strong><a style={{color:"#4b5e71"}} href="mailto:contact@koffy.finance">contact@koffy.finance</a></Strong><br />
                téléphone : 04 58 10 50 91<br />
                
                <br /><br /><Strong>ARTICLE 2 - Prix</Strong><br /><br />
                Les Services sont fournis aux tarifs en vigueur figurant sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>..., lors
                de l'enregistrement de la commande par le Prestataire.
                Les prix sont exprimés en Euros, HT et TTC.
                Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire
                sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>.
                Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Prestataire
                se réserve le droit, hors période de validité, d’en modifier les prix à tout moment.
                Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais.
                Une facture peut-être établie par le Prestataire et remise au Client lors de la fourniture des
                Services commandés.
                
                <br /><br /><Strong>ARTICLE 3 – Commandes</Strong><br /><br />
                Il appartient au Client de sélectionner sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> les Services qu'il désire
                commander, selon les modalités suivantes :
                Le Client choisi une formule d'abonnement, avant de valider sa commande, procéder au
                paiement et d’accepter les présentes conditions générales de vente. Après validation de la
                formule, la commande sera considérée comme définitive et exigera paiement de la part du
                Client selon les modalités prévues..
                La vente ne sera considérée comme valide qu’après paiement intégral du prix. Il appartient
                au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.
                Toute commande passée sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> constitue la formation d'un contrat
                conclu à distance entre le Client et le Prestataire.
                Le Prestataire se réserve le droit d'annuler ou de refuser toute commande d'un Client avec
                lequel il existerait un litige relatif au paiement d'une commande antérieure.
                La passation d'une commande sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> implique la conclusion d'un
                contrat d'une durée minimum de 1 mois / 1 an renouvelable pour une même durée par
                tacite reconduction.<br /><br />
                Aux termes de l'article L 215 -1 du Code de la consommation, reproduit ci-dessous :
                "Pour les contrats de prestations de services conclus pour une durée déterminée avec une
                clause de reconduction tacite, le professionnel prestataire de services informe le
                consommateur par écrit, par lettre nominative ou courrier électronique dédiés, au plus tôt
                trois mois et au plus tard un mois avant le terme de la période autorisant le rejet de la
                reconduction, de la possibilité de ne pas reconduire le contrat qu'il a conclu avec une clause
                de reconduction tacite. Cette information, délivrée dans des termes clairs et
                compréhensibles, mentionne, dans un encadré apparent, la date limite de non-reconduction.
                Lorsque cette information ne lui a pas été adressée conformément aux dispositions du
                premier alinéa, le consommateur peut mettre gratuitement un terme au contrat, à tout
                moment à compter de la date de reconduction.<br /><br />
                Les avances effectuées après la dernière date de reconduction ou, s'agissant des contrats à
                durée indéterminée, après la date de transformation du contrat initial à durée déterminée,
                sont dans ce cas remboursées dans un délai de trente jours à compter de la date de
                résiliation, déduction faite des sommes correspondant, jusqu'à celle-ci, à l'exécution du
                contrat. Les dispositions du présent article s'appliquent sans préjudice de celles qui
                soumettent légalement certains contrats à des règles particulières en ce qui concerne
                l'information du consommateur. »
                L'article L215-2 du Code de la consommation exclut l'application de l'art L215-1 aux
                exploitants des services d'eau potable et d'assainissement, à l’inverse l'article L215-3 du
                Code de la consommation, dispose que ces règles sont applicables aux contrats conclus entre
                des professionnels et des non-professionnels.
                L'article L241-3 du sanctionne le professionnel qui n'aurait pas procédé aux remboursements
                dans les conditions prévues à l'article L 215-1 du Code de la consommation"
                
                <br /><br /><Strong>ARTICLE 4 - Conditions de paiement</Strong><br /><br />
                Le prix est payé par voie de paiement sécurisé, selon les modalités suivantes :
                • paiement par carte bancaire
                Le prix est payable comptant par le Client, en totalité au jour de la passation de la
                commande.
                Les données de paiement sont sont échangées en mode chiffré grâce au protocole défini par
                le prestataire de paiement agréé intervenant pour les transactions bancaires réalisées sur le
                site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>.
                Les paiements effectués par le Client ne seront considérés comme définitifs qu'après
                encaissement effectif des sommes dues, par le Prestataire.
                Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le
                Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.
                
                <br /><br /><Strong>ARTICLE 5 - Fourniture des Prestations</Strong><br /><br />
                Les Services commandés par le Client seront fournis selon les modalités suivantes :
                L'activation de l'abonnement intervient sous 10 minutes après validation du paiement par
                notre prestataire Stripe…<br /><br />
                Lesdits Services seront fournis dans un délai maximum de 1h (maximum), habituellement
                sous 10 minutes à compter de la validation définitive de la commande du Client, dans les
                conditions prévues aux présentes CGV à l'adresse indiquée par le Client lors de sa commande
                sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> .
                Le Prestataire s'engage à faire ses meilleurs efforts pour fournir les Services commandés par
                le Client, dans le cadre d'une obligation de moyen et dans les délais ci-dessus précisés.
                Si les Services commandés n'ont pas été fournis dans un délai de 1 jour. après la date
                indicative de fourniture, pour toute autre cause que la force majeure ou le fait du Client, la
                vente des Services pourra être résolue à la demande écrite du Client dans les conditions
                prévues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation. Les sommes
                versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui
                suivent la date de dénonciation du contrat, à l'exclusion de toute indemnisation ou retenue.
                En cas de demande particulière du Client concernant les conditions de fourniture des
                Services, dûment acceptées par écrit par le Prestataire, les coûts y étant liés feront l'objet
                d'une facturation spécifique complémentaire ultérieure.<br /><br />
                A défaut de réserves ou réclamations expressément émises par le Client lors de la réception
                des Services, ceux-ci seront réputés conformes à la commande, en quantité et qualité.
                Le Client disposera d'un délai de 7 jours à compter de la fourniture des Services pour émettre
                des réclamations par mail à l'adresse <Strong><a style={{color:"#4b5e71"}} href="mailto:contact@koffy.finance">contact@koffy.finance</a></Strong>.
                Le client doit préciser [LITIGE] dans l'objet du mail, avec tous les justificatifs y afférents,
                auprès du Prestataire.<br /><br />
                Aucune réclamation ne pourra être valablement acceptée en cas de non respect de ces
                formalités et délais par le Client.
                Le Prestataire remboursera ou rectifiera dans les plus brefs délais et à ses frais les Services
                dont le défaut de conformité aura été dûment prouvé par le Client.
                
                <br /><br /><Strong>ARTICLE 6 - Droit de rétractation</Strong><br /><br />
                Compte tenu de la nature des Services fournis, les commandes passées par le Client ne
                bénéficient pas du droit de rétractation.
                Le contrat est donc conclu de façon définitive dès la passation de la commande par le Client
                selon les modalités précisées aux présentes CGV.
                
                <br /><br /><Strong>ARTICLE 7 - Responsabilité du Prestataire - Garanties</Strong><br /><br />
                Le Prestataire garantit, conformément aux dispositions légales et sans paiement
                complémentaire, le Client, contre tout défaut de conformité ou vice caché, provenant d'un
                défaut de conception ou de réalisation des Services commandés dans les conditions et selon
                les modalités suivantes :
                <br /><br />
                Dispositions relatives aux garanties légales<br /><br />
                Article L217-4 du Code de la consommation
                « Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de
                conformité existant lors de la délivrance. Il répond également des défauts de conformité
                résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a
                été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. »
                <br /><br />
                Article L217-5 du Code de la consommation
                « Le bien est conforme au contrat :<br />
                1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :<br />
                - s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a
                présentées à l'acheteur sous forme d'échantillon ou de modèle ;<br />
                - s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux
                déclarations publiques faites par le vendeur, par le producteur ou par son représentant,
                notamment dans la publicité ou l'étiquetage ;<br /><br />
                2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est
                propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et
                que ce dernier a accepté. »
                <br /><br />
                Article L217-12 du Code de la consommation
                « L'action résultant du défaut de conformité se prescrit par deux ans à compter de la
                délivrance du bien. »
                <br /><br />
                Article L217-16 du Code de la consommation.
                « Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui
                lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en
                état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient
                s'ajouter à la durée de la garantie qui restait à courir. Cette période court à compter de la
                demande d'intervention de l'acheteur ou de la mise à disposition pour réparation du bien en
                cause, si cette mise à disposition est postérieure à la demande d'intervention. »
                Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit (mail ou
                courrier), de l'existence des vices ou défauts de conformité.
                Le Prestataire remboursera ou rectifiera ou fera rectifier (dans la mesure du possible) les
                services jugés défectueux dans les meilleurs délais et au plus tard dans les 7 jours jours
                suivant la constatation par le Prestataire du défaut ou du vice. Ce remboursement pourra
                être fait par virement ou chèque bancaire.
                La garantie du Prestataire est limitée au remboursement des Services effectivement payés
                par le Client.
                <br /><br />
                Le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou
                inexécution consécutif à la survenance d'un cas de force majeure habituellement reconnu
                par la jurisprudence française.
                Les Services fournis par l'intermédiaire du site stripe.com du Prestataire sont conformes à la
                réglementation en vigueur en France. La responsabilité du Prestataire ne saurait être
                engagée en cas de non respect de la législation du pays dans lequel les Services sont fournis,
                qu'il appartient au Client, qui est seul responsable du choix des Services demandés, de
                vérifier.

                <br /><br /><Strong>ARTICLE 8 - Données personnelles</Strong><br /><br />
                Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la
                vente des Services et leur réalisation et leur délivrance, ainsi qu'à leur transmission à des
                tiers intervenant dans la réalisation des Services. Ces données à caractère personnel sont
                récoltées uniquement pour l’exécution du contrat de prestations de services.
                <br /><br />
                8.1 Collecte des données à caractère personnel
                Les données à caractère personnel qui sont collectées sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> sont les
                suivantes :
                Ouverture de compte
                Lors de la création du compte utilisateur :
                Adresse e-mail, mot de passe.
                Paiement
                Dans le cadre du paiement des Prestations proposés sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>, celui-ci
                enregistre des données financières relatives au compte bancaire ou à la carte de crédit du
                Client / utilisateur ainsi qu’a la facturation (nom(s), prénom(s), adresse complète, numéro de
                téléphone).
                <br /><br />
                8.2 Destinataires des données à caractère personnel
                Les données à caractère personnel sont utilisées par le Prestataire et ses co-contractants
                pour l’exécution du contrat et pour assurer l’efficacité de la prestation de services, sa
                réalisation et sa délivrance.
                La ou les catégorie(s) de co-contractant(s) est (sont) :<br />
                • Les prestataires établissements de paiement<br />
                Le responsable de traitement des données est le Prestataire, au sens de la loi Informatique et
                libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des données
                à caractère personnel.
                <br /><br />
                8.4 limitation du traitement
                Les données à caractère personnelles du Client ne sont pas utilisées à des fins publicitaires
                ou marketing externe à Koffy.
                <br /><br />
                8.5 Durée de conservation des données
                Le Prestataire conservera les données ainsi recueillies pendant un délai de 5 ans, couvrant le
                temps de la prescription de la responsabilité civile contractuelle applicable.
                <br /><br />
                8.6 Sécurité et confidentialité
                Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et
                physiques en matière de sécurité du numérique pour protéger les données personnelles
                contre les altérations, destructions et accès non autorisés. Toutefois il est à signaler
                qu’Internet n’est pas un environnement complètement sécurisé et le Prestataire ne peut
                garantir la sécurité de la transmission ou du stockage des informations sur Internet.
                <br /><br />
                8.7 Mise en œuvre des droits des Clients et utilisateurs
                En application de la règlementation applicable aux données à caractère personnel, les Clients
                et utilisateurs du site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> disposent des droits suivants :
                • Ils peuvent mettre à jour ou supprimer les données qui les concernent de la manière
                suivante :<br />
                Par mail à l'adresse <Strong><a style={{color:"#4b5e71"}} href="mailto:contact@koffy.finance">contact@koffy.finance</a></Strong>.
                Le client doit préciser [RGPD] dans l'objet du mail.<br />
                • Ils peuvent supprimer leur compte en écrivant à l’adresse électronique indiqué à
                l’article 9.3 « Responsable de traitement »<br />
                • Ils peuvent exercer leur droit d’accès pour connaître les données personnelles les
                concernant en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement
                »<br />
                • Si les données à caractère personnel détenues par le Prestataire sont inexactes, ils
                peuvent demander la mise à jour des informations des informations en écrivant à
                l’adresse indiqué à l’article 9.3 « Responsable de traitement »<br />
                • Ils peuvent demander la suppression de leurs données à caractère personnel,
                conformément aux lois applicables en matière de protection des données en
                écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »<br />
                • Ils peuvent également solliciter la portabilité des données détenues par le
                Prestataire vers un autre prestataire<br />
                • Enfin, ils peuvent s’opposer au traitement de leurs données par le Prestataire
                <br /><br />
                Ces droits, dès lors qu’ils ne s’opposent pas à la finalité du traitement, peuvent être exercé
                en adressant une demande par courrier ou par E-mail au Responsable de traitement dont les
                coordonnées sont indiquées ci-dessus.
                <br /><br />
                Le responsable de traitement doit apporter une réponse dans un délai maximum d’un mois.
                En cas de refus de faire droit à la demande du Client, celui-ci doit être motivé.
                Le Client est informé qu’en cas de refus, il peut introduire une réclamation auprès de la CNIL
                (3 place de Fontenoy, 75007 PARIS) ou saisir une autorité judiciaire.
                Le Client peut être invité à cocher une case au titre de laquelle il accepte de recevoir des
                mails à caractère informatifs et publicitaires de la part du Prestataire. Il aura toujours la
                possibilité de retirer son accord à tout moment en contactant le Prestataire (coordonnées ci-
                dessus) ou en suivant le lien de désabonnement.
                
                <br /><br /><Strong>ARTICLE 9 - Propriété intellectuelle</Strong><br /><br />
                Le contenu du site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> est la propriété du Vendeur et de ses partenaires et
                est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.
                Toute reproduction totale ou partielle de ce contenu est strictement interdite et est
                susceptible de constituer un délit de contrefaçon.
                
                <br /><br /><Strong>ARTICLE 10 - Droit applicable - Langue</Strong><br /><br />
                Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit
                français.
                Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites
                en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.
                
                <br /><br /><Strong>ARTICLE 11 - Litiges</Strong><br /><br />
                Pour toute réclamation merci de contacter le service clientèle à l’adresse postale ou mail du
                Prestataire indiquée à l’ARTICLE 1 des présentes CGV.
                Le Client est informé qu'il peut en tout état de cause recourir à une médiation
                conventionnelle, auprès des instances de médiation sectorielles existantes ou à tout mode
                alternatif de règlement des différends (conciliation, par exemple) en cas de contestation.
                Le Client est également informé qu’il peut, également recourir à la plateforme de Règlement
                en Ligne des Litige (RLL) :
                <br /><br />
                <Strong><a style={{color:"#4b5e71"}} href='https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show' target='_blank' rel="noreferrer">https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show</a></Strong>
                <br /><br />
                Tous les litiges auxquels les opérations d'achat et de vente conclues en application des
                présentes CGV et qui n’auraient pas fait l’objet d’un règlement amiable entre le vendeur ou
                par médiation, seront soumis aux tribunaux compétents dans les conditions de droit
                commun.
                    
                </p>
                </Box>
            </section>

            <Legal_footer />

        </>
    );
}

export default CGV;