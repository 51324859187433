import React, {useState, useEffect} from 'react';
import { Text, Box, Link } from '@radix-ui/themes';
import { DrawingPinFilledIcon } from '@radix-ui/react-icons';

// Error pages
import Error404 from "../errors/404";

const ShortLink = () => {

    // this page is format /shortlink?code=CODE_HERE
    // where id is a code that is used in database to find the link
    // and redirect to it

    // get the code from the url
    let code = ""
    try {
        code = window.location.search.split('=')[1].split('code')[0].split('&')[0]
    } catch (error) {
        
    }

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        // contact the api to get the user data
        fetch("https://kissaten.koffy.finance/urlshort/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({code: code}),
        })
        .then(res => res.json())
        .then(data => {
            setUserData(data);
            setLoading(false);
        })
    }, [code]);
    
    if(loading) return <></> // Add a loading indicator
    

    if(userData.error === "bad_request_code") {
        // show the 404 page
        return <Error404/>
    }

    // redirect to the link
    // in userData.url

    document.location.href = userData.url;

    return (
        <>
            <Box css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <DrawingPinFilledIcon width="20" height="20" />
                    <Text size={8} css={{ marginTop: '20px' }}>
                        Redirecting to 
                        <Link href={userData.url}>
                            {userData.url}
                        </Link>
                    </Text>
                </Box>
            </Box>
        </>
    )

}

export default ShortLink;