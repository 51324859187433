import React from 'react';
import { Box, Text, Grid } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const Twitter2 = (twitter) => {

    setTimeout(() => {
        document.getElementById("twtov_twtmostintr").innerHTML = twitter.twitterData.twtov_twtmostintr
        document.getElementById("twtov_lstmin").innerHTML = twitter.twitterData.twtov_lstmin
    }, 100);

    return  <Grid style={{marginTop: '30px', display: checkSreenInf750() ? "block" : "flex"}}>

        <Box style={{
            height: '100%',
            width: checkSreenInf750() ? '100%' : "50%",
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: checkSreenInf750() ? '20px' : '0px',
        }}>
            <Box style={{
                height: '90%',
                width: '90%',
                display: "block",
            }}>
                <Text size="5" weight="bold">
                    
                    {
                        twitter.userData.userData.usrsett_lang === "FR" ?
                            "Tweet avec le plus d’interaction" :
                            "Tweet with the most interaction"
                    }
                </Text>
                <br />
                <br />
                <Text size="3" id="twtov_twtmostintr"> </Text>
            </Box>
        </Box>


        <Box style={{
            height: '100%',
            width: checkSreenInf750() ? '100%' : "50%",
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: checkSreenInf750() ? '20px' : '0px',
        }}>
            <Box style={{
                height: '90%',
                width: '90%',
                display: "block"
            }}>
                <Text size="5" weight="bold">
                    {
                        twitter.userData.userData.usrsett_lang === "FR" ?
                            "Tweet de dernière minute" :
                            "Last minute tweet"
                    }
                </Text>
                <br />
                <br />
                <Text size="3" id="twtov_lstmin"></Text>
            </Box>
        </Box>

    </Grid>

}

export default Twitter2;