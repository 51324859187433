import React from 'react';
import { Flex, Text, Button, Box, Dialog, Link, HoverCard, Strong  } from '@radix-ui/themes';
import { ResetIcon} from '@radix-ui/react-icons';


function redirectUser() {
    // get the date
    let date = document.getElementById('tmdate').value;
    // check date is valid with regex
    let regex = new RegExp('^\\d{4}-\\d{2}-\\d{2}$');
    if (regex.test(date)) {
        // redirect user
        window.location.href = '/time-machine/' + date;
    }
}

function getScreenWidth() {
    return window.innerWidth;
}

function display50(base) {
    if(getScreenWidth() < 1000) {
        return "48%";
    }
    return base;
}

/**
 * This is the header of the logged user
 * This is used to diplay the main informations
 */
const Lib = (userData) => {

    return  <Dialog.Root>
            <Dialog.Trigger>
               <Button variant="outline" color='gray' style={{ width: display50("auto"), marginLeft: "1px", }}>
                    <ResetIcon width="25" height="18" /> <Text>
                        {' '}
                        <HoverCard.Root>
                            <HoverCard.Trigger>
                            <Link href="#">
                                <Strong>    
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Time Machine" :
                                        "Time Machine"
                                    }
                                </Strong>
                            </Link>
                            </HoverCard.Trigger>
                            <HoverCard.Content>
                            <Flex gap="4">
                                <Box>
                                    <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                            "La " :
                                            "The "
                                        }
                                        <Strong>time machine 🕘</Strong>
                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                            " vous permet de voir une version antérieure du daily. Simplement pour remonter le temps, et analyser le passé. C'est aussi simple que ça !" :
                                            " allows you to see a previous version of the daily. Simply to go back in time, and analyze the past. It's as simple as that!"
                                        }
                                    </Text>
                                </Box>
                            </Flex>
                            </HoverCard.Content>
                        </HoverCard.Root>{' '}
                    </Text>
                </Button>
            </Dialog.Trigger>

            <Dialog.Content style={{ maxWidth: 450 }}>
                <form
                onSubmit={(event) => {
                    event.preventDefault();
                    redirectUser();
                }}>
                    <Dialog.Title>Time Machine 🕰</Dialog.Title>
                    <Dialog.Description size="2" mb="4">
                        {
                            userData.userData.usrsett_lang === "FR" ?
                            "C'est comme Marty McFly, sauf que nous on n'a pas de voiture pour revenir dans le futur, car nous sommes le futur":
                            "It's like Marty McFly, except we don't have a car to go back to the future, because we are the future"
                        }
                    </Dialog.Description>

                    <Flex direction="column" gap="3">
                        <label>
                            <input type='date' id='tmdate'/>
                        </label>
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                            Cancel
                            </Button>
                        </Dialog.Close>
                        <Dialog.Close>
                            <Button type='submit'>
                                {
                                    userData.userData.usrsett_lang === "FR" ?
                                    "Ouvrir 🏎️":
                                    "Open 🏎️"
                                }
                            </Button>
                        </Dialog.Close>
                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>


}

export default Lib;