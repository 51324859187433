import React from 'react';
import { Box, Text, Grid, HoverCard, Link, Flex, Strong } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

function purgeString(str) {
    // Purge non-alphanumeric characters from the cryptoName
    return str.replace(/[^a-zA-Z0-9]/g, '');
}

function getCryptoLink(cryptoName) {

    const purgedCryptoName = purgeString(cryptoName)
    
    // Generate the link
    const link = `https://coinmarketcap.com/currencies/${purgedCryptoName}/`;

    return link;
}

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

const Twitter1 = (twitter) => {

    return <Grid style={{marginTop: '20px', display: checkSreenInf750() ? "block" : "flex"}}>
                
    <Box style={{
        height: '100%',
        width: checkSreenInf750() ? '100%' : "33%",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: checkSreenInf750() ? '20px' : '0px',
    }}>
        <Text size="5" weight="bold" style={{
            alignContent: 'center',
            display: 'flex',
        }}>
            {twitter.twitterData.twtov_mostinf}
        </Text>

        <Text size="5" style={{
            alignContent: 'center',
            display: 'flex',
        }}>
            {
                twitter.userData.userData.usrsett_lang === "FR" ?
                    "Profil le plus influent" :
                    "Most influential profile"
            }
        </Text>
    </Box>
    

    <Box style={{
        height: '100%',
        width: checkSreenInf750() ? '100%' : "33%",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: checkSreenInf750() ? '20px' : '0px',
    }}>

        {
            checkSreenInf750() ? <Text size="5" weight="bold" style={{
                alignContent: 'center',
                display: 'flex',
            }}>
                {twitter.twitterData.twtov_mosthpdcrypto}
            </Text>
            :

            <Text size="5" weight="bold" style={{
                alignContent: 'center',
                display: 'flex',
            }}>
                <a target='_blank' rel="noreferrer" href={getCryptoLink(twitter.twitterData.twtov_mosthpdcrypto)}>
                    {' '}
                    <HoverCard.Root>
                        <HoverCard.Trigger>
                        <Link style={{color: 'black'}}>
                            {twitter.twitterData.twtov_mosthpdcrypto}
                        </Link>
                        </HoverCard.Trigger>
                        <HoverCard.Content>
                        <Flex gap="4">
                            <Box>
                                <Text as="div" size="2" style={{ maxWidth: 300 }}>
                                    Clique ici pour en savoir plus sur le cours du <Strong>{purgeString(twitter.twitterData.twtov_mosthpdcrypto)}</Strong> en direct ⏱
                                </Text>
                            </Box>
                        </Flex>
                        </HoverCard.Content>
                    </HoverCard.Root>{' '}
                </a>
            </Text>

        }

        <Text size="5" style={{
            alignContent: 'center',
            display: 'flex',
        }}>
            {
                twitter.userData.userData.usrsett_lang === "FR" ?
                    "Crypto la plus mentionnée" :
                    "Most mentioned crypto"
            }
        </Text>
    </Box>

    <Box style={{
        height: '100%',
        width: checkSreenInf750() ? '100%' : "33%",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: checkSreenInf750() ? '20px' : '0px',
    }}>
        <Text size="5" weight="bold" style={{
            alignContent: 'center',
            display: 'flex',
        }}>
            {twitter.twitterData.twtov_feeling}
        </Text>

        <Text size="5" style={{
            alignContent: 'center',
            display: 'flex',
        }}>
            {
                twitter.userData.userData.usrsett_lang === "FR" ?
                    "Sentiment global du Crypto Twitter " :
                    "Global sentiment of Crypto Twitter"
            }
        </Text>
    </Box>

</Grid>

}

export default Twitter1;