// RegisterForm.js
import React from 'react';

import { createPopup } from '../../../js/popup'


function getScreenSizeForTextSize() {
    if(window.innerWidth < 768) {
      return true
    }
    return false
  }
  
const RegisterForm = () => {

    let lang = "en"

    const classEnable = "flex-1 border-2 border-black border-solid rounded-lg px-4 py-3 mt-6 mr-2"
    const classDisable = "flex-1 bg-black hover:bg-white hover:bg-gray-200 hover:text-black text-white font-semibold rounded-lg px-4 py-3 mt-6 mr-2"

    setTimeout(() => {
        document.getElementById("btn_lang_en").addEventListener("click", function() {
            lang = "en"
            
            // enable btn_lang_en and disable btn_lang_fr
            document.getElementById("btn_lang_en").className = classEnable
            document.getElementById("btn_lang_fr").className = classDisable
            document.getElementById("lang_name").innerHTML = "Anglais 🇺🇸"
        });
    
        document.getElementById("btn_lang_fr").addEventListener("click", function() {
            lang = "fr"
    
            // enable btn_lang_fr and disable btn_lang_en
            document.getElementById("btn_lang_fr").className = classEnable
            document.getElementById("btn_lang_en").className = classDisable
            document.getElementById("lang_name").innerHTML = "Français 🇫🇷"
        });

    }, 1000);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
    
        const data = {
            email: formData.get("email"),
            password: formData.get("password"),
            timezone: formData.get("timezone"),
            language: lang,
        };

    
        const res = await fetch("https://kissaten.koffy.finance/user/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
    
        const body = await res.json();
        if(body.success === true) {
            alert("Votre compte a bien été créé, merci de verifier vos emails pour confirmer votre compte")
            window.location.href = "/login";
        } else {
            createPopup(body.error_description)
        }
        
    };

    setTimeout(() => {
        document.getElementById("btn_lang_fr").click()
    }, 200);

  return (
        <div className="w-full h-100">
            <form onSubmit={handleSubmit}>
                <div>
                    <img className="h-12 mb-5" src='/imgs/koffy_logo.png' alt='koffy logo' />
                    <h1 className="text-xl md:text-2xl font-pt-serif font-bold leading-tight mt-12"><span
                            className="bg-underline1 bg-left-bottom bg-no-repeat pb-3 bg-100%">
                            Bienvenue, enregistrez-vous ! </span></h1>
                    <div className="mt-4">
                        <label className="font-montserrat block text-gray-700">Adresse e-mail</label>
                        <input id="email" name="email" type="email" placeholder="Adresse e-mail"
                            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-black focus:bg-white focus:outline-none" required />
                    </div>
                    <div  className="mt-4">
                        <label className="font-montserrat block text-gray-700">Mot de passe</label>
                        <input id="password" name="password" type="password" placeholder="Mot de passe" minLength="6" className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-black
                    focus:bg-white focus:outline-none" required />
                    </div>

                    <div className="mt-4">
                        <label className="font-montserrat block text-gray-700">Votre timezone</label>
                        <select id="timezone" name="timezone" required className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-black
                                focus:bg-white focus:outline-none">
                            <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                            <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                            <option value="-10:00">(GMT -10:00) Hawaii</option>
                            <option value="-09:50">(GMT -9:30) Taiohae</option>
                            <option value="-09:00">(GMT -9:00) Alaska</option>
                            <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                            <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                            <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                            <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                            <option value="-04:50">(GMT -4:30) Caracas</option>
                            <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                            <option value="-03:50">(GMT -3:30) Newfoundland</option>
                            <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                            <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                            <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                            <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                            <option value="+01:00" selected="selected">(GMT +1:00) Paris, Brussels, Copenhagen, Madrid</option>
                            <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                            <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                            <option value="+03:50">(GMT +3:30) Tehran</option>
                            <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                            <option value="+04:50">(GMT +4:30) Kabul</option>
                            <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                            <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                            <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                            <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                            <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                            <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                            <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                            <option value="+08:75">(GMT +8:45) Eucla</option>
                            <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                            <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                            <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                            <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                            <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                            <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                            <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                            <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                            <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                            <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                            </select>
                    </div>

                    <div className="mt-4">
                        <label className="font-montserrat block text-gray-700">Choix de la langue: <span id="lang_name">Anglais 🇺🇸</span></label>
                        <div className="flex justify-between">
                            <button type="button" id="btn_lang_en" className="flex-1 border-2 border-black border-solid rounded-lg px-4 py-3 mt-6 mr-2">Anglais 🇺🇸</button>
                            <button type="button" id="btn_lang_fr" className="flex-1 bg-black hover:bg-white hover:bg-gray-200 hover:text-black text-white font-semibold rounded-lg px-4 py-3 mt-6 mr-2">Français 🇫🇷</button>
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="w-full block bg-black hover:bg-white hover:bg-gray-200 hover:text-black text-white font-semibold rounded-lg
                    px-4 py-3 mt-6">S’inscrire 🗞️</button>
                    </div>

                    <p className="mt-4 font-montserrat">
                        Vous avez déjà un compte ? <a href="/login" className="text-blue-500 hover:text-blue-700 font-semibold">Connectez-vous</a>
                    </p>

                    <hr className="my-6 border-gray-300 w-full" />

                    <p className="mt-8 font-montserrat">
                        En vous inscrivant sur Koffy, vous acceptez les 
                        <a href='/cgv' target='_blank' className="text-blue-700 hover:text-blue-900 font-semibold"> conditions générales d’utilisation </a>
                        et les 
                        <a href='/cgv' target='_blank' className="text-blue-700 hover:text-blue-900 font-semibold"> conditions générales d’abonnement</a>
                    </p>

                    
                    
                    {
                      // APP BE LIKE
                      (!getScreenSizeForTextSize()) ? (
                        <p className="mt-4 font-montserrat">
                          <a href='/' className="text-blue-600 hover:text-blue-700 font-semibold"> Revenir à l'accueil</a>
                        </p>
                      ) : (<></>)
                    }
                    
                    <div></div>
                </div>
            </form>
        </div>
  );
};

export default RegisterForm;
