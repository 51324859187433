import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '@radix-ui/themes/styles.css';
import { Theme } from '@radix-ui/themes';

// Homes page
import Home from "./pages/base/home";
import HomeLogged from "./pages/base_logged/home";
import TimeMachine from "./pages/base_logged/time-machine";
import Settings from "./pages/base_logged/settings";
import Library from "./pages/base_logged/library";

// Auth pages
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotUI from "./pages/auth/forgot";
import ResetUi from "./pages/auth/reset";

// Legal page
import Legal from "./pages/legal/legal";
import CGU from "./pages/legal/cgu";
import CGV from "./pages/legal/cgv";

// Koffy
import Me from "./pages/koffy/me";
import ForceReload from "./pages/bugs/force_reload.jsx";

// Short link
import ShortLink from "./pages/shortlink/main";

// Error pages
import Error404 from "./pages/errors/404";

// rss feed
import Rss from "./pages/base_logged/rss";

// Subscribe service
import Subscribe from './pages/subscribe/main.jsx'
import Wait from './pages/subscribe/wait.jsx'

// Email validation
import EmailValidation from './pages/email/email.jsx'
import AutoLogin from './pages/autologin/main.jsx';
import Espresso from './pages/base_logged/Espresso.jsx';

// listen if the screen size change
window.addEventListener('resize', () => {

  // check if the screen size is small
  if(window.innerWidth > 600) {
    // reload this component
    //window.location.reload();
  }
});

function isEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

// Cors allow: api.coingecko.com

function App() {

  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true); // Add a loading state

  //show the current poath
  console.log(window.location.pathname);

  useEffect(() => {
    const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
    const token = cookie ? cookie.split('token=')[1] : null;
    if (!token) {
      // Handle case where token is not available
      setLoading(false);
      return;
    }

    // contact the api to get the user data
    fetch("https://kissaten.koffy.finance/me", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    .then(res => res.json())
    .then(data => {
      if(data.newcookie) {
        // delete the old cookie
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // update the cookie
        document.cookie = data.newcookie;
      }
      if (!isEqual(userData, data.data)) {
        setUserData(data.data);
      }
      if (loading) {
        setLoading(false);
      }
    })
    .catch(error => {
      // Handle fetch errors
      console.error('Error fetching user data:', error);
      setLoading(false);
    });
  }, [loading, userData]);

  if(loading) return (
    <ForceReload />
  )


  if(userData == null || userData === undefined) { 
    return (
      <BrowserRouter>
        <Theme>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/autologin/*" element={<AutoLogin />} />
            <Route path="/app/scripts/autologin.php*" element={<AutoLogin />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot" element={<ForgotUI />} />
            <Route path="/reset/*" element={<ResetUi />} />
            <Route path="legal" element={<Legal />} />
            <Route path="cgu" element={<CGU />} />
            <Route path="/legal/cgu_fr" element={<CGU />} />
            <Route path="cgv" element={<CGV />} />
            <Route path="shortlink" element={<ShortLink />} />
            <Route path="/verify/*" element={<EmailValidation />} />
            <Route path="*" element={<Error404/>} />
          </Routes>
        </Theme>
      </BrowserRouter>
    );
  } 

  // email validation
  if(userData.usr_mail_verified === 0) {
    // payment page only
    return (
      <BrowserRouter>
        <Theme>
          <Routes>
            <Route path="*" element={<EmailValidation/>} />
          </Routes>
        </Theme>
      </BrowserRouter>
    );
  }

  if(userData.usr_isbeta === 0 && userData.usr_ispremium === 0) {
    // payment page only
    return (
      <BrowserRouter>
        <Theme>
          <Routes>
            <Route path="wait" element={<Wait userData={userData}/>} />
            <Route path="*" element={<Subscribe userData={userData}/>} />
          </Routes>
        </Theme>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Theme>
        <Routes>
          <Route index element={<HomeLogged userData={userData} />} />
          <Route path="news" element={<Rss userData={userData} />} />
          <Route path='/espresso/*' element={<Espresso userData={userData} />} />
          <Route path='/time-machine/*' element={<TimeMachine userData={userData} />} />
          <Route path="/autologin/*" element={<AutoLogin />} />
          <Route path="/app/scripts/autologin.php*" element={<AutoLogin />} />
          <Route path="me" element={<Me userData={userData} />} />
          <Route path="settings" element={<Settings userData={userData}/>} />
          <Route path='library' element={<Library userData={userData}/>} />
          <Route path="legal" element={<Legal />} />
          <Route path="cgu" element={<CGU />} />
          <Route path="/legal/cgu_fr" element={<CGU />} />
          <Route path="cgv" element={<CGV />} />
          <Route path="shortlink" element={<ShortLink />} />
          <Route path="wait" element={<Wait userData={userData}/>} />
          <Route path="*" element={<Error404/>} />
        </Routes>
      </Theme>
    </BrowserRouter>
  );
}

export default App;
