import React, { useEffect, useState } from 'react';
import { Box, Text, Card, Inset, Link, Button  } from '@radix-ui/themes';


import useSound from "use-sound"; // for handling the sound
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai"; // icons for play and pause
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi"; // icons for next and previous track
import { IconContext } from "react-icons"; // for customazing the icons


const EspressoPlayer = (data) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const [play, { pause, duration, sound }] = useSound(data.data[0].pod_url_mp3);


    const playingButton = () => {
        if (isPlaying) {
            pause(); // this will pause the audio
            setIsPlaying(false);
        } else {
            play(); // this will play the audio
            setIsPlaying(true);
        }
    };

    const [currTime, setCurrTime] = useState({
        min: "",
        sec: "",
      }); // current position of the audio in minutes and seconds
    
    const [seconds, setSeconds] = useState(); // current position of the audio in seconds]
    const [time, setTime] = useState({
        min: Math.floor(duration / 60000),
        sec: Math.floor((duration / 1000) % 60),
    }); // total duration of the audio in minutes and seconds
    

    useEffect(() => {
        const sec = duration / 1000;
        const min = Math.floor(sec / 60);
        const secRemain = Math.floor(sec % 60);
        setCurrTime({
          min: min,
          sec: secRemain,
        });
        setTime({
          min: min,
          sec: secRemain,
        });
    }, [duration]);
      
    useEffect(() => {
        const interval = setInterval(() => {
          if (sound) {
            setSeconds(sound.seek([])); // setting the seconds state with the current state
            const min = Math.floor(sound.seek([]) / 60);
            const sec = Math.floor(sound.seek([]) % 60);
            setCurrTime({
              min,
              sec,
            });
          }
        }, 1000);
        return () => clearInterval(interval);
    }, [sound]);
    


    return (
        <>
            <div className="component">
                <Text size="2" weight="bold">
                    Playing Now
                </Text>
                <img
                    className="musicCover"
                    src="/imgs/espresso.png"
                    style={{ width: "100%", padding: "10px", borderRadius: "10%" }}
                />
                <div>
                    <Text size="4" weight="bold">
                        Espresso du {data.data[0].pod_date}
                    </Text>
                    <br/>
                    <br/>
                    <p className="subTitle">Koffy production</p>
                </div>
                <div>
                    <div className="time">
                    <p>
                        {currTime.min}:{currTime.sec}
                    </p>
                    <p>
                        {time.min}:{time.sec}
                    </p>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max={duration / 1000}
                        default="0"
                        value={seconds}
                        className="timeline"
                        onChange={(e) => {
                            sound.seek([e.target.value]);
                        }}
                    />
                </div>
                <div>
                    <button className="playButton">
                    <IconContext.Provider value={{ size: "3em", color: "#4f4f4f" }}>
                        <BiSkipPrevious />
                    </IconContext.Provider>
                    </button>
                    {!isPlaying ? (
                    <button className="playButton" onClick={playingButton}>
                        <IconContext.Provider value={{ size: "3em", color: "#4f4f4f" }}>
                            <AiFillPlayCircle />
                        </IconContext.Provider>
                    </button>
                    ) : (
                    <button className="playButton" onClick={playingButton}>
                        <IconContext.Provider value={{ size: "3em", color: "#4f4f4f" }}>
                            <AiFillPauseCircle />
                        </IconContext.Provider>
                    </button>
                    )}
                    <button className="playButton">
                    <IconContext.Provider value={{ size: "3em", color: "#4f4f4f" }}>
                        <BiSkipNext />
                    </IconContext.Provider>
                    </button>
                </div>
            </div>
        </>
    );
}

export default EspressoPlayer;
