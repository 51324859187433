import React from 'react';
import { Box, Text } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

import Header from "../compo/header.jsx";

/******* FUCKING APP LIKE */
function getScreenSizeForTextSize() {
    if(window.innerWidth < 768) {
        return '90%'
    } else {
        return '25%'
    }
  }

const ToOld = (koffyData) => {


    return <>
        <Header userData={koffyData.userData} />
         {/* box full heigth full width with an image in the center */}

            <Box style={{ backgroundColor: 'white', height: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "40px" }}>
                <Box style={{ display: "block", height: '25%', width: getScreenSizeForTextSize(), justifyContent: "center", marginBottom: "40px"}}>
                    <img src="/imgs/gif/toold.gif" alt="loading" style={{
                        width: '100%',
                        objectFit: 'cover',
                    }} />
                
                    <Text size='6' style={{ color: 'black' }} weight="bold">
                        Erreur : Données non disponibles pour la date spécifiée
                    </Text>
                    <br />
                    <Text size='3' style={{ color: 'black', marginBottom: "40px" }} weight="bold">
                        Désolé, mais les données que vous avez demandées ne sont pas accessibles pour la date que vous avez fournie. Veuillez vérifier la date ou, si possible, utilisez une date plus récente pour obtenir les informations souhaitées.
                    </Text>
                </Box>
            </Box>
    </>

}

export default ToOld;