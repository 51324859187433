import React from 'react';
import { Box, Text, Flex, RadioGroup, Card, Strong, Separator, Button } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';

import { createPopup } from '../../../js/popup'


function getActualLanguage(data) {
    if(data.usrsett_lang.toLowerCase() === "fr") {
        return "french";
    } 
    return "english";
}

function getActualExchange(data) {
    return data.usrsett_ref_exchange;
}

function getActualCur(data) {
    return data.usrsett_curr_index.toLowerCase();
}

function getActualNewsletter(data) {
    try {
        return data.usr_nonewsletter === 0 ? "yes" : "no";
    } catch (error) {
        return "no";
    }
}

function isTimezoneActive(data, timezone) {
    if(data.usrsett_timezone === timezone) {
        return true;
    }
    return false;
}

function getScreenWidth() {
    return window.innerWidth;
}

function MarketPlace_display() {
    if(getScreenWidth() < 600) {
        return "column";
    }
    return "row";
}

function MarketPlace_maxSize() {
    if(getScreenWidth() < 600) {
        return "100%";
    }
    return "47%";
}

function smallBoxPhone(base) {
    if(getScreenWidth() < 800) {
        return "100%";
    }
    return base;
}

function smallBoxPhoneHuge(base) {
    if(getScreenWidth() < 850) {
        return "100%";
    }
    return base;
}

function smallBoxPhoneHeigth(base) {
    if(getScreenWidth() < 600) {
        return "auto";
    }
    return base;
}

function isKofeedActive(data) {
    if(data.usrsett_custom_feed === 1) {
        return "enable";
    }
    return "disable";
}


async function contactApi(path, body) {
    try {
        const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
        const token = cookie ? cookie.split('token=')[1] : null;
        const req = await fetch("https://kissaten.koffy.finance"+path, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
        return await req.json()
    } catch (error) {
        return null
    }
}

const Settings_detail = (userData) => {

    // Work on the lang service
    async function settings_lang(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("settings_lang")) {
            await contactApi("/settings/update/lang", {
                lang: formData.get("settings_lang") === "english" ? "en" : "fr"
            })
            // reload the page
            window.location.reload();
        }
    }
    function submitSettings_lang() {
        try { document.getElementById("settings_lang_submit").click();} catch(err){}
    }


    // work on the MarketPlace service
    async function settings_market(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("settings_market")) {
            await contactApi("/settings/update/marketplace", {
                marketplace: formData.get("settings_market")
            })
        }
    }
    function submitSettings_market() {
        try { document.getElementById("settings_market_submit").click();} catch(err){}
    }

    // work on the curency service
    async function settings_cur(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("settings_cur")) {
            await contactApi("/settings/update/devise", {
                devise: formData.get("settings_cur")
            })
        }
        
    }
    function submitSettings_cur() {
        try { document.getElementById("settings_cur_submit").click();} catch(err){}
    }


    async function settings_newsletter(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("settings_newsletter")) {
            await contactApi("/settings/update/newsletter", {
                newsletter: formData.get("settings_newsletter")
            })
        }
        
    }
    function submitSettings_newsletter() {
        try { document.getElementById("settings_newsletter_submit").click();} catch(err){}
    }

    // work on the kofeed service
    async function settings_kofeed(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("settings_kofeed")) {
            await contactApi("/settings/update/customfeed/enabled", {
                enabled: formData.get("settings_kofeed")
            })
        }
    }
    function submitSettings_kofeed() {
        try { document.getElementById("settings_kofeed_submit").click();} catch(err){}
    }


    // work on the timezone service
    async function settings_timezone(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("settings_timezone")) {
            await contactApi("/settings/update/timezone", {
                timezone: formData.get("settings_timezone")
            })
        }
    }
    function submitSettings_timezone() {
        try { document.getElementById("settings_timezone_submit").click();} catch(err){}
    }

    // work on the kofeed tags service
    async function updateTags(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get("kofeed_tags")) {
            await contactApi("/settings/update/customfeed/keyword", {
                tags: formData.get("kofeed_tags")
            })


            if(userData.userData.usrsett_lang === "FR") {
                createPopup("Vos paramètres KoFeed ont été mis à jour")
            } else {
                createPopup("Your KoFeed settings have been updated")
            }
        }
    }

    async function deleteAccount(e) {
        e.preventDefault();
        const t = await contactApi("/settings/delete/check", {})
        if(t) {
            if(!t.error) {
                if(t.data) {
                    // show popup
                    //alert("Account have a valid subscription, please contact us to delete your account: contact@koffy.finance")
                }
            }
            alert("Account have a valid subscription, please contact us to delete your account: contact@koffy.finance")
        }
    }


    const u = userData.userData

    return <>
        <Box style={{marginTop: "40px", display: "flex", justifyContent: 'flex-start', flexWrap: "wrap", boxSizing: "border-box", padding: '10px'}}>

            
            <Flex direction="column" gap="3" style={{margin: '12px', width: "23%", minWidth: smallBoxPhone("240px"), minHeight: smallBoxPhoneHeigth("100%")}}>

                <Card size="2" style={{ width: "100%", height: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>
                            {
                                userData.userData.usrsett_lang === "FR" ?
                                    "Langue" :
                                    "Language"
                            }
                        </Strong>
                    </Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2" style={{marginTop: "20px"}}>
                        <Box className="mt-2">
                            <form onSubmit={settings_lang} onChange={submitSettings_lang}>
                                <RadioGroup.Root defaultValue={getActualLanguage(u)} name="settings_lang">
                                    <Flex gap="2" direction="column">
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="english"/> English 🇺🇸
                                            </Flex>
                                        </Text>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="french"/> French 🇫🇷
                                            </Flex>
                                        </Text>
                                    </Flex>
                                </RadioGroup.Root>

                                <Button size="1" style={{ display: "none" }} id="settings_lang_submit"></Button>
                            </form>
                        </Box>
                    </Box>
                </Card>

            </Flex>

            <Flex direction="column" gap="3" style={{margin: '12px', width: "47%", minWidth: MarketPlace_maxSize(), minHeight: smallBoxPhoneHeigth("100%")}}>

                <Card size="2" style={{ width: "100%", height: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>
                            {
                                userData.userData.usrsett_lang === "FR" ?
                                    "Place de marché" :
                                    "Marketplace"
                            }
                        </Strong>
                    </Text>
                    <Text size={"1"}>Le prix de la section market dans votre édition</Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2" style={{marginTop: "20px"}}>
                        <Box className="mt-2">
                            <form onSubmit={settings_market} onChange={submitSettings_market}>
                                <RadioGroup.Root defaultValue={getActualExchange(u)} name="settings_market">
                                    <Flex gap="2" direction={MarketPlace_display()} style={{ flexWrap: "wrap" }}>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="Kraken" /> Kraken
                                            </Flex>
                                        </Text>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="Binance" /> Binance
                                            </Flex>
                                        </Text>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="Bitfinex" /> Bitfinex
                                            </Flex>
                                        </Text>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="CoinBase" /> CoinBase
                                            </Flex>
                                        </Text>
                                    </Flex>
                                </RadioGroup.Root>
                                <Button size="1" style={{ display: "none" }} id="settings_market_submit"></Button>
                            </form>
                        </Box>
                    </Box>
                </Card>

            </Flex>

            <Flex direction="column" gap="3" style={{margin: '12px', width: "23%",  minWidth: smallBoxPhone("200px"), minHeight: smallBoxPhoneHeigth("100%")}}>

                <Card size="2" style={{ width: "100%", height: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>
                            {
                                userData.userData.usrsett_lang === "FR" ?
                                    "Devise de référence" :
                                    "Reference currency"
                            }
                        </Strong>
                    </Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2" style={{marginTop: "20px"}}>
                        <Box className="mt-2">
                            <form onSubmit={settings_cur} onChange={submitSettings_cur}>
                                <RadioGroup.Root defaultValue={getActualCur(u)} name="settings_cur">
                                    <Flex gap="2" direction="row">
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="eur" /> Euro (€)
                                            </Flex>
                                        </Text>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="usd" /> US Dollar ($)
                                            </Flex>
                                        </Text>
                                    </Flex>
                                </RadioGroup.Root>
                                <Button size="1" style={{ display: "none" }} id="settings_cur_submit"></Button>
                            </form>
                        </Box>
                    </Box>
                </Card>

            </Flex>

            <Flex direction="column" gap="3" style={{margin: '12px', width: "23%",  minWidth: smallBoxPhone("200px"), minHeight: smallBoxPhoneHeigth("100%")}}>

                <Card size="2" style={{ width: "100%", height: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>
                            {
                                userData.userData.usrsett_lang === "FR" ?
                                    "Newsletter" :
                                    "Daily newsletter"
                            }
                        </Strong>
                    </Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2" style={{marginTop: "20px"}}>
                        <Box className="mt-2">
                            <form onSubmit={settings_newsletter} onChange={submitSettings_newsletter}>
                                <RadioGroup.Root defaultValue={getActualNewsletter(u)} name="settings_newsletter">
                                    <Flex gap="2" direction="row">
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="yes" /> 
                                                {
                                                    userData.userData.usrsett_lang === "FR" ?
                                                        "Oui" :
                                                        "Yes"
                                                }
                                            </Flex>
                                        </Text>
                                        <Text as="label" size="2">
                                            <Flex gap="2">
                                                <RadioGroup.Item value="no" /> 
                                                {
                                                    userData.userData.usrsett_lang === "FR" ?
                                                        "Non" :
                                                        "No"
                                                }
                                            </Flex>
                                        </Text>
                                    </Flex>
                                </RadioGroup.Root>
                                <Button size="1" style={{ display: "none" }} id="settings_newsletter_submit"></Button>
                            </form>
                        </Box>
                    </Box>
                </Card>

            </Flex>

            <Flex direction="column" gap="3" style={{margin: '12px', width: "23%",  minWidth: smallBoxPhone("400px"), minHeight: smallBoxPhoneHeigth("100%")}}>

                <Card size="2" style={{ width: "100%", height: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>Time Zone</Strong>
                    </Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2">
                        <Box className="mt-2">
                            <form onSubmit={settings_timezone} onChange={submitSettings_timezone}>
                                <select id="timezone" name="settings_timezone" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-black focus:border-black focus:z-10 sm:text-sm">
                                    <option value="-12:00" selected={isTimezoneActive(u, '-12:00')}>(GMT -12:00) Eniwetok, Kwajalein</option>
                                    <option value="-11:00" selected={isTimezoneActive(u, '-11:00')}>(GMT -11:00) Midway Island, Samoa</option>
                                    <option value="-10:00" selected={isTimezoneActive(u, '-10:00')}>(GMT -10:00) Hawaii</option>
                                    <option value="-09:50" selected={isTimezoneActive(u, '-09:30')}>(GMT -9:30) Taiohae</option>
                                    <option value="-09:00" selected={isTimezoneActive(u, '-09:00')}>(GMT -9:00) Alaska</option>
                                    <option value="-08:00" selected={isTimezoneActive(u, '-08:00')}>(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                    <option value="-07:00" selected={isTimezoneActive(u, '-07:00')}>(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                    <option value="-06:00" selected={isTimezoneActive(u, '-06:00')}>(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                    <option value="-05:00" selected={isTimezoneActive(u, '-05:00')}>(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                    <option value="-04:50" selected={isTimezoneActive(u, '-04:30')}>(GMT -4:30) Caracas</option>
                                    <option value="-04:00" selected={isTimezoneActive(u, '-04:00')}>(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                    <option value="-03:50" selected={isTimezoneActive(u, '-03:30')}>(GMT -3:30) Newfoundland</option>
                                    <option value="-03:00" selected={isTimezoneActive(u, '-03:00')}>(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                    <option value="-02:00" selected={isTimezoneActive(u, '-02:00')}>(GMT -2:00) Mid-Atlantic</option>
                                    <option value="-01:00" selected={isTimezoneActive(u, '-01:00')}>(GMT -1:00) Azores, Cape Verde Islands</option>
                                    <option value="+00:00" selected={isTimezoneActive(u, '+00:00')}>(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                    <option value="+01:00" selected={isTimezoneActive(u, '+01:00')}>(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                    <option value="+02:00" selected={isTimezoneActive(u, '+02:00')}>(GMT +2:00) Kaliningrad, South Africa</option>
                                    <option value="+03:00" selected={isTimezoneActive(u, '+03:00')}>(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                    <option value="+03:50" selected={isTimezoneActive(u, '+03:30')}>(GMT +3:30) Tehran</option>
                                    <option value="+04:00" selected={isTimezoneActive(u, '+04:00')}>(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                    <option value="+04:50" selected={isTimezoneActive(u, '+04:30')}>(GMT +4:30) Kabul</option>
                                    <option value="+05:00" selected={isTimezoneActive(u, '+05:00')}>(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                    <option value="+05:50" selected={isTimezoneActive(u, '+05:30')}>(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                    <option value="+05:75" selected={isTimezoneActive(u, '+05:45')}>(GMT +5:45) Kathmandu, Pokhara</option>
                                    <option value="+06:00" selected={isTimezoneActive(u, '+06:00')}>(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                    <option value="+06:50" selected={isTimezoneActive(u, '+06:30')}>(GMT +6:30) Yangon, Mandalay</option>
                                    <option value="+07:00" selected={isTimezoneActive(u, '+07:00')}>(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                    <option value="+08:00" selected={isTimezoneActive(u, '+08:00')}>(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                    <option value="+08:75" selected={isTimezoneActive(u, '+08:45')}>(GMT +8:45) Eucla</option>
                                    <option value="+09:00" selected={isTimezoneActive(u, '+09:00')}>(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                    <option value="+09:50" selected={isTimezoneActive(u, '+09:30')}>(GMT +9:30) Adelaide, Darwin</option>
                                    <option value="+10:00" selected={isTimezoneActive(u, '+10:00')}>(GMT +10:00) Eastern Australia, Guam, VlaBoxostok</option>
                                    <option value="+10:50" selected={isTimezoneActive(u, '+10:30')}>(GMT +10:30) Lord Howe Island</option>
                                    <option value="+11:00" selected={isTimezoneActive(u, '+11:00')}>(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                    <option value="+11:50" selected={isTimezoneActive(u, '+11:30')}>(GMT +11:30) Norfolk Island</option>
                                    <option value="+12:00" selected={isTimezoneActive(u, '+12:00')}>(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                    <option value="+12:75" selected={isTimezoneActive(u, '+12:45')}>(GMT +12:45) Chatham Islands</option>
                                    <option value="+13:00" selected={isTimezoneActive(u, '+13:00')}>(GMT +13:00) Apia, Nukualofa</option>
                                    <option value="+14:00" selected={isTimezoneActive(u, '+14:00')}>(GMT +14:00) Line Islands, Tokelau</option>
                                </select>
                                <Button size="1" style={{ display: "none" }} id="settings_timezone_submit"></Button>
                            </form>
                        </Box>
                    </Box>
                </Card>

            </Flex>

            <Flex direction="column" gap="3" style={{margin: '12px', width: "60%",  minWidth: smallBoxPhoneHuge("700px")}}>

                <Card size="2" style={{ width: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>KoFeed</Strong>
                    </Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2">

                        <Flex gap="2" direction={MarketPlace_display()} style={{ flexWrap: "wrap", display:"flex" }}>

                            <Box style={{width: smallBoxPhoneHuge("60%")}}>
                                <Flex direction="column" gap="3" style={{margin: '5px', width: "100%"}}>
                                    <Box className="col-span-4 sm:col-span-2">
                                        <Box className="mt-2">
                                        <form onSubmit={settings_kofeed} onChange={submitSettings_kofeed}>
                                                <RadioGroup.Root defaultValue={isKofeedActive(u)} name='settings_kofeed'>
                                                    <Flex gap="2" direction="row">
                                                        <Text as="label" size="2">
                                                            <Flex gap="2">
                                                                <RadioGroup.Item value="enable" /> Enabled
                                                            </Flex>
                                                        </Text>
                                                        <Text as="label" size="2">
                                                            <Flex gap="2">
                                                                <RadioGroup.Item value="disable" /> Disabled
                                                            </Flex>
                                                        </Text>
                                                    </Flex>
                                                </RadioGroup.Root>
                                                <Button size="1" style={{ display: "none" }} id="settings_kofeed_submit"></Button>
                                            </form>
                                        </Box>
                                    </Box>
                                </Flex>
                                <Box className="mt-2">
                                    <Text>
                                        

                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                                `My KoFeed vous permet de définir des mots-clés, ces balises seront utilisées pour le titre et le contenu 
                                                rechercher pour vous permettre de rechercher des sujets spécifiques qui seront affichés dans l’édition 
                                                si des articles sont disponibles. Nous vous recommandons d’entrer les mots-clés les plus prioritaires 
                                                Entrez les mots-clés et ajoutez un espace entre eux. 
                                                La limite est de 10 mots-clés.` :
                                                `My KoFeed allows you to define keywords, these tags will be used for title and content 
                                                search to allow you to search for specific topics that will be displayed in the edition 
                                                if articles are available. We recommend that you enter the highest priority keywords 
                                                first as they will be searched for first. Enter the keywords and add a space between them. 
                                                The limit is 10 keywords.`
                                        }
                                    </Text>
                                </Box>
                            </Box>

                            <Box style={{width: smallBoxPhoneHuge("38%")}}>
                                <form onSubmit={updateTags}>
                                    <Box className="col-span-4 sm:col-span-2 items-center justify-center">
                                        <Box className="w-4/4">
                                            <textarea 
                                                id="kofeed_tags" name="kofeed_tags" className="w-full p-2 border-2 border-gray rounded-md mb-4" 
                                                rows="6" placeholder="Type your keywords here..."
                                                defaultValue={u.usrsett_custom_tags}>
                                                
                                            </textarea>
                                            <Box id="tags-container" className="flex flex-wrap"></Box>
                                        </Box>
                                    </Box>
                                    {/* Submit button */}
                                    <Box>
                                        <Button size="3" type='submit' style={{width: "100%"}}>
                                            <Text style={{color: "white"}}>
                                                Update
                                            </Text>
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Flex>

                    </Box>
                </Card>

            </Flex>

            <Flex direction="column" gap="3" style={{margin: '12px', width: "23%", minWidth: smallBoxPhone("240px"), minHeight: smallBoxPhoneHeigth("100%")}}>

                <Card size="2" style={{ width: "100%", height: "100%" }}>
                    <Text as="p" size="6">
                        <Strong>
                            {
                                userData.userData.usrsett_lang === "FR" ?
                                    "Compte" :
                                    "Account"
                            }
                        </Strong>
                    </Text>
                    <Separator my="3" size="4" />
                    <Box className="col-span-4 sm:col-span-2" style={{marginTop: "20px"}}>
                        <Box className="mt-2">
                            <Box>
                                <Button size="3" type='submit' color='red' style={{width: "100%"}} onClick={deleteAccount}>
                                    <Text style={{color: "white"}}>
                                        {
                                            userData.userData.usrsett_lang === "FR" ?
                                                "Supprimer mon compte" :
                                                "Delete my account"
                                        }
                                    </Text>
                                </Button>
                                   
                                <Text>
                                    <br />
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                            "Cette action est irréversible et supprimera toutes vos données." :
                                            "This action is irreversible and will delete all your data."
                                    }
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Card>

            </Flex>
            
        </Box>

    </>

}

export default Settings_detail;
