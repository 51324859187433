import React from 'react';

import { Box, Strong } from '@radix-ui/themes';

import Legal_header from './block/header.jsx';
import Legal_footer from './block/footer.jsx';

const CGU = () => {
    return (
        <>

            <Legal_header />

            <section>
            <Box className="max-w-screen-xl mx-auto p-8">
            <h2 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 text-gray-900 mb-12">
                Conditions générales d'utilisation 🇫🇷
            </h2>
            <p className="text-lg leading-6 text-gray-900">
                <p>En vigueur au 08/03/2022<br /><br />Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d'utilisation
                (dites &laquo; CGU &raquo;) ont pour objet l'encadrement juridique des modalit&eacute;s de mise &agrave;
                disposition du site et des services par Koffy Finance et de d&eacute;finir les conditions d&rsquo;acc&egrave;s
                et d&rsquo;utilisation des services par &laquo; l'utilisateur &raquo;. Les pr&eacute;sentes CGU sont
                accessibles sur le site &agrave; la rubrique &laquo;CGU&raquo;. <br />Toute inscription ou utilisation du site
                implique l'acceptation sans aucune r&eacute;serve ni restriction des pr&eacute;sentes CGU par
                l&rsquo;utilisateur. Lors de l'inscription sur le site via le Formulaire d&rsquo;inscription, chaque
                utilisateur accepte express&eacute;ment les pr&eacute;sentes CGU en validant son inscription au
                service&raquo;. <br />En cas de non-acceptation des CGU stipul&eacute;es dans le pr&eacute;sent contrat,
                l'utilisateur se doit de renoncer &agrave; l'acc&egrave;s des services propos&eacute;s par le site.
                <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> se r&eacute;serve le droit de modifier unilat&eacute;ralement et &agrave; tout moment le
                contenu des pr&eacute;sentes CGU. <br /><br /><Strong>Article 1 - Les mentions l&eacute;gales </Strong></p>
                <p><br />L'&eacute;diteur du site Koffy.Finance est :<br />Koffy.Finance<br />Si&egrave;ge social - Montreuil
                93100, France<br />Siret - 90058667800014<br /><br />H&eacute;bergeur :<br />Koffy.finance est
                h&eacute;berg&eacute; par Aqua Ray S.A.S<br />Si&egrave;ge social - 14 rue Jules Vanzuppe 94200
                IVRY-SUR-SEINE<br />FRANCE<br />SIREN - RCS Cr&eacute;teil 447 997 099<br /><br />Webmaster - Alexandre
                A.<br />Responsable de publication - Alexandre A.</p>
                <p>Nous sommes joignables &agrave; l'adresse email suivante : contact@koffy.finance</p><br />
                <p><Strong>Article 2 - Acc&egrave;s au site</Strong></p><br />
                <p>Le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> permet à l'utilisateur un accès gratuit ou payant aux services suivants
                :<br />Le site internet propose les services suivants :</p>
                <p>- Koffy.finance fourni un résumé et un condensé d'articles de presses, informations financières, offres
                d'emplois etc.</p>
                <p>- Nous fournissons un agrégateur d'informations à nos utilisateurs.</p>
                <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
                supportés par l'utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet,
                etc.) sont à sa charge. L'acc&egrave;s &agrave; certaines section du site peut n&eacute;c&eacute;ssiter un
                compte, un abonnement payant.</p>
                <p>L&rsquo;Utilisateur non membre n'a pas acc&egrave;s aux services r&eacute;serv&eacute;s. Pour cela, il doit
                s&rsquo;inscrire en remplissant le formulaire. En acceptant de s&rsquo;inscrire aux services
                r&eacute;serv&eacute;s, l&rsquo;Utilisateur membre s&rsquo;engage &agrave; fournir des informations
                sinc&egrave;res et exactes concernant ses coordonn&eacute;es, notamment son adresse email.</p>
                <p>Pour accéder aux services, l&rsquo;Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de
                son mot de passe.</p>
                <p>Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en envoyant un
                courriel &agrave; l'adresse mentionn&eacute;e dans l'ARTICLE 1. Celle-ci sera effective dans un délai
                raisonnable. Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site
                ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la
                responsabilité de <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>. Dans ces cas, l&rsquo;Utilisateur accepte ainsi ne pas tenir rigueur
                à l&rsquo;éditeur de toute interruption ou suspension de service, même sans préavis.<br />L'utilisateur a la
                possibilité de contacter le site par messagerie électronique à l&rsquo;adresse email de l&rsquo;éditeur
                communiqué à l&rsquo;ARTICLE 1.</p>
                <br />
                <p><Strong>Article 3 - Collecte des donn&eacute;es<br /></Strong></p><br />
                <p>Le site assure à l'utilisateur une collecte et un traitement d'informations personnelles dans le respect de
                la vie privée. Dans le cadre du RGPD : vous disposez d'un droit de r&eacute;traction, modification et
                suppression sur vos donn&eacute;es, pour en disposer, merci de nous envoyer un mail en incluant "[RGPD]" dans
                l'objet de votre mail.</p>
                <p>Nous n'utilisons pas de cookies pour identifier votre session, les m&eacute;triques sont
                r&eacute;colt&eacute;es via l'outil UMAMI (<a href="https://umami.is/">https://umami.is/</a>), les
                donn&eacute;es sont anonymes et l'utilisateur n'est pas identifi&eacute; ou identifiable.</p>
                <p>N&eacute;anmoins, &agrave; des fins de statistique et dans le but d'am&eacute;liorer notre service, nous
                stockons nottament la date de votre derni&egrave;re connexion &agrave; notre service.</p>
                <br />
                <p><Strong>Article 4 - Propri&eacute;t&eacute; intellectuelle<br /></Strong></p><br />
                <section>
                <p>A l'exception des contenus clairement identifi&eacute;s comme
                    appartenant &agrave; nos partenaires, des tiers qui peuvent apparaitre sur Koffy.finance, tous les
                    &eacute;l&eacute;ments distinctifs (logo, textes, nom de domaine, marque) qui peuvent apparaitre sur le site
                    (<Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>) demeurent notre propri&eacute;t&eacute; exclusive. Toute repr&eacute;sentation de
                    ces &eacute;l&eacute;ments, l'emploi de notre nom, et toute association est formellement interdite sans
                    notre accord &eacute;crit pr&eacute;alable, tout usage nomm&eacute; ci-dessus est ill&eacute;gal et peut
                    donner suite &agrave; des poursuites judiciaires.</p>
                <br />
                <p><Strong>Article 5 - Responsabilit&eacute;</Strong></p><br />
                <p>Les sources des informations diffusées sur le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> sont réputées fiables mais le site
                    ne garantit pas qu&rsquo;il soit exempt de défauts, d&rsquo;erreurs ou d&rsquo;omissions.<br />Les
                    informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des
                    mises à jour régulières, le site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong> ne peut être tenu responsable de la modification des
                    dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être
                    tenue responsable de l&rsquo;utilisation et de l&rsquo;interprétation de l&rsquo;information contenue dans
                    ce site par l'utilisateur.</p>
                <p>L'utilisateur s'assure de garder son mot de passe secret. Toute Boxulgation du mot de passe, quelle que
                    soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe.
                    Le site décline toute responsabilité.<br /></p>
                <p>La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
                    insurmontable d'un tiers.</p>
                <br />
                <p><Strong>Article 6 - Lien hypertexte</Strong></p><br />
                <p>Des liens hypertextes peuvent être présents sur le site. L&rsquo;Utilisateur est informé qu&rsquo;en
                    cliquant sur ces liens, il sortira du site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>. Ce dernier n&rsquo;a pas de contrôle sur
                    les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur
                    contenu.</p>
                <br />
                <p><Strong>Article 7 - Cookies</Strong></p><br />
                <p>L&rsquo;Utilisateur est informé que lors de ses visites sur le site, un cookie peut s&rsquo;installer
                    automatiquement sur son logiciel de navigation.<br />Les cookies sont de petits fichiers stockés
                    temporairement sur le disque dur de l&rsquo;ordinateur de l&rsquo;Utilisateur par votre navigateur et qui
                    sont nécessaires à l&rsquo;utilisation du site <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>. Les cookies ne contiennent pas
                    d&rsquo;information personnelle et ne peuvent pas être utilisés pour identifier quelqu&rsquo;un. Un cookie
                    contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de
                    la visite de l&rsquo;Utilisateur.</p>
                <p>L&rsquo;information contenue dans les cookies est utilisée pour améliorer le site
                    <Strong><a style={{color:"#4b5e71"}} href="#">https://koffy.finance</a></Strong>.<br />En naviguant sur le site, L&rsquo;Utilisateur les accepte.</p>
                <p>Les Cookies utilis&eacute;s sur Koffy.finance sont des cookies de session et sont d&eacute;truits lorsque
                    l'utilisateur reste inactif ou se d&eacute;connecte.</p>
                <br />
                <p><Strong>Article 8 - Droit et Juridiction comp&eacute;tente</Strong></p><br />
                <p>
                    La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige
                    né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
                </p>
                </section>
            </p>
            </Box>
            </section>

            <Legal_footer />

        </>
    );
}

export default CGU;