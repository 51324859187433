import React from 'react';
import { Box } from '@radix-ui/themes';

import Header from './compo/header.jsx';
import Body from './compo/body.jsx';
import Footer from '../utils/footer.jsx'

function getprettyDate(lang) {
    // get today's date in french
    var today = new Date();
    if(lang === "FR") {
        return today.toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
    return today.toLocaleDateString("en-EN", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}

function getTodayDate() {
    // date regex test: YYYY-MM-DD
    return new Date().toISOString().slice(0, 10);
}

function checkSreenInf750() {
    const width = window.innerWidth;
    if(width < 750) return true;
    else return false;
}

function getTimeMachineDate() {
    // get the curent url
    return window.location.href.split("/")[4];
}

/**
 * This page is the home page for the logged user
 * This is used to diplay the main informations
 */
const HomePageLogged = ({ userData, time }) => {
    if(!time) time = getTodayDate();

    return (
        <>
            <Box>
                <Header userData={userData}/>
            </Box>

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">
                        {
                            // check if url contain /time-machine/
                            window.location.href.includes("/time-machine/") ?
                                " (Time Machine | " + getTimeMachineDate() + ")" :
                                userData.usrsett_lang === "FR" ?
                                    "Edition du: "+getprettyDate(userData.usrsett_lang) :
                                    "News of: "+getprettyDate(userData.usrsett_lang)
                        }
                    </span>
                </div>
                <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500"> Koffy - Since 2022 </span>
                </div>
            </div>

            {
                checkSreenInf750() ?
                    <Box style={{marginTop: "20px", marginRight: "10px", marginLeft: "10px"}}>
                        <Body userData={userData} time={time}/>
                    </Box>
                :
                    <Box style={{marginTop: "20px", marginRight: "100px", marginLeft: "100px"}}>
                        <Body userData={userData} time={time}/>
                    </Box>
            }

            {
                checkSreenInf750() ?
                   <></>
                :
                <Box>
                    <Footer userData={userData}/>
                </Box>
            }

        </>
    );
}

export default HomePageLogged;
