import React from 'react';
import { Button, Box, Link } from '@radix-ui/themes';
import { RocketIcon, PersonIcon } from '@radix-ui/react-icons';

const Top = () => {

    const login = () => {
        document.location.href = '/login'
    }

    const register = () => {
        document.location.href = '/register'
    }

    return <>

        <Box style={{ width: "100%", height: '8vh', display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>

            {/* Icon in the top left */}
            <Box style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                {/* Add your icon component or image here */}
                <img src='/imgs/koffy_logo.png' width="50px" alt='koffy logo' style={{marginRight: '10vh'}}/>

                {
                    (window.innerWidth > 768) ?
                    (
                        <>
                            <Link style={{ margin: '0 10px', color: "black" }} href='#feature'>Fonctionnalités</Link>
                            <Link style={{ margin: '0 10px', color: "black" }} href='#b2b'>B2B</Link>
                            <Link style={{ margin: '0 10px', color: "black" }} href='#pricing'>Tarifs</Link>
                        </>
                    ) : <></>
                }
                
            </Box>



            <Box style={{ marginRight: "10%", textAlign: "right", display: "flex" }}>

                <Button size="3" variant="outline" style={{ marginRight: '7%' }} color='gold' onClick={login}>
                    <PersonIcon width="16" height="16" /> Connexion
                </Button>

                {
                    (window.innerWidth > 768) ?
                    (
                        <Button size="3" style={{ marginRight: '7%'}} color='gold' onClick={register}>
                            <RocketIcon width="16" height="16" /> Créer un compte
                        </Button>
                    ) : <></>
                }

            </Box>
        </Box>
    
    </>

}

export default Top;