import React from 'react';
import { Text, Box } from '@radix-ui/themes';
import { DrawingPinFilledIcon } from '@radix-ui/react-icons';

import SubscribeBox2 from './content2'

import Content from './popup/code.jsx';

import Onpc from './onpc'

function checkWindowSize() {
  if(window.innerWidth < 768) {
      return true
  } else {
      return false
  }
}


const Subscribe = (userData) => {
  if(checkWindowSize()) {
    return <Onpc />
  }

  return (
    <Box style={{
      backgroundColor: "#f7f7f7",
      height: "100vh",
      width: "100vw",
    }}>
        <Box style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
        }}>
            <Box style={{
              margin: "auto",
              display: "flex",
              marginTop: "25px",
            }}>
              <img src='/imgs/koffy_logo.png' alt='koffy logo' height={"60px"} width={"60px"}/>
            </Box>
        </Box>

        {/** Info box */}
        <Box style={{
          display: "flex",
          marginTop: "30px",
          justifyContent: "center", // Center horizontally
          alignItems: "center",     // Center vertically
          marginLeft: "30px",
          marginRight: "30px",
        }}>
          <Box style={{
            backgroundColor: "rgba(31, 41, 55)",
            color: "white",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px"
          }}>
            <Text weight={"bold"} style={{
              marginLeft: "10px",
              marginRight: "10px",
              display: "flex",
              marginTop: "4px",
              marginBottom: "4px"
            }}>
              <DrawingPinFilledIcon style={{color: "#FFFFFF", marginRight: "10px", height: "25px"}}/>

                {
                  userData.userData.usrsett_lang === "FR" ?
                  " Afin de nous permettre de nous développer et de soutenir notre aventure, Koffy nécessite un abonnement payant." :
                  " In order to allow us to develop and support our adventure, Koffy requires a paid subscription."
                }
            </Text>
          </Box>

        </Box>


      <SubscribeBox2 userData={userData.userData}/>

      {/** Now the btn for the code popup */}
      <Content userData={userData.userData}/>

    </Box>
  )
}

export default Subscribe