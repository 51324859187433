import React, {useState, useEffect} from 'react';

const AutoLogin = () => {

    // this page is format /shortlink?code=CODE_HERE
    // where id is a code that is used in database to find the link
    // and redirect to it

    // check if user is allready logged in
    try {
        const token = document.cookie.split("token=")[1].split(";")[0];
        if(token) {
            document.location.href = "/"
        }
    } catch (error) {}

    // get the code from the url
    let code = ""
    try {
        code = window.location.href.split("/autologin/")[1].split("/")[0];
    } catch (error) {
        
    }

    if(window.location.href.includes("app/scripts/autologin.php")) {
        try {
            code = window.location.href.split("code=")[1].split("&")[0];
        } catch (error) {
            
        }
    }

    console.log("Using code: " + code)

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        // contact the api to get the user data
        fetch("https://kissaten.koffy.finance/user/autologin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({code: code}),
        })
        .then(res => res.json())
        .then(data => {
            setUserData(data);
            setLoading(false);
        })
    }, [code]);
    
    if(loading) return <></> // Add a loading indicator

    if(userData.cookie) {
        // set the token cookie and redirect to the link
        document.cookie = "token=" + userData.cookie + "; path=/";
    }

    document.location.href = "/"

}

export default AutoLogin;