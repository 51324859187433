import React from 'react';
import { Flex, Text, Button, Box, Link, HoverCard, Strong } from '@radix-ui/themes';
import { ChatBubbleIcon } from '@radix-ui/react-icons';


function display(base) {
    if(getScreenWidth() < 1000) {
        return "100%";
    }
    return base;
}

function getScreenWidth() {
    return window.innerWidth;
}


function marginTopSize() {
    if(getScreenWidth() < 1546) {
        return "10px";
    }
    return 0;
}

function generate_link() {
    // link: /espresso/YYYY-MM-DD
    let date = new Date();
    let year = date.getFullYear();
    let month = inect_zero(date.getMonth() + 1);
    let day = inect_zero(date.getDate());

    return "/espresso/" + year + "-" + month + "-" + day;
}


function inect_zero(num) {
    if(num < 10) {
        return "0" + num;
    }
    return num;
}

const espresso = (userdata) => {

    let link = generate_link();

    return (
        <Link href={link} color='gray' style={{ width: display('auto'), marginTop: marginTopSize() }}>
            <Button variant="outline" style={{marginRight: "1em",  width: display('auto')}}>
                <ChatBubbleIcon width="25" height="18" /> <Text>
                    {' '}
                    <HoverCard.Root>
                        <HoverCard.Trigger>
                        <Link href={link}>
                            Espresso
                        </Link>
                        </HoverCard.Trigger>
                        <HoverCard.Content>
                        <Flex gap="4">
                            <Box>
                                <Text as="div" size="2" style={{ maxWidth: 300 }} mt="3">
                                    {
                                        userdata.userData.usrsett_lang === "FR" ?
                                        (<>Votre podcast crypto quotidien</>) :
                                        (<>Your daily crypto podcast</>)
                                    }
                                </Text>
                            </Box>
                        </Flex>
                        </HoverCard.Content>
                    </HoverCard.Root>{' '}
                </Text>
            </Button>
        </Link>
    )
}

export default espresso;