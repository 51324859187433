import React, { useEffect } from 'react';
//import { } from '@radix-ui/react-icons';


const ForceReload = () => {

    useEffect(() => {
        setTimeout(() => {
            // reload page
            if(document.location.href.includes("/app") && !document.location.href.includes("/app/script")) {
                console.log("reload")
                window.location.href = "/"
            }
            
        }, 100)
    }, []);

    return <></>

}

export default ForceReload;