import React from 'react';

// ui part
import PictureSide from './login/PictureSide';
import RegisterForm from './login/RegisterForm';

const RegisterUI = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100vh', overflow: "hidden" }}>
      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
        flex items-center justify-center">
        <RegisterForm />
      </div>
      <div className="bg-gray-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
        <PictureSide />
      </div>
    </div>
  );
}

export default RegisterUI;
